// library
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// services
import api from "services/api";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";

// components
import AppFooter from "components/app-footer";
import AppNavbar from "components/app-navbar";
import AppNewsCard from "components/app-news-card";
import AppPagination from "components/app-pagination";
import ProjectShowCaseInfo from "components/pages/page-project-show-case/app-project-show-case-info";

const PageProjectShowCase = () => {
	const { id } = useParams();
	const [videoIndex, setVideoIndex] = useState<number>(0);
	const [videoKey, setVideoKey] = useState(Date.now());
	const [projectData, setProjectData] = useState<any>();

	const fetchProjectData = useCallback(async () => {
		let response = null;

		if (!id) return;

		try {
			response = await api.get.clients.client(id);

			setProjectData(response.data.data);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, [id]);

	const onHandlePlayBackVideo = useCallback(() => {
		if (videoIndex !== 0) {
			setVideoIndex((prev) => --prev);

			setVideoKey(Date.now());
		}
	}, [videoIndex]);

	const onHandlePlayNextVideo = useCallback(() => {
		if (projectData?.medias && videoIndex < projectData.medias.length - 1) {
			setVideoIndex((prev) => prev + 1);

			setVideoKey(Date.now());
		}
	}, [videoIndex, projectData?.medias]);

	const getYouTubeEmbedUrl = (url: string): string => {
		const videoId = url.split("v=")[1]?.split("&")[0] || url.split("youtu.be/")[1];

		return `https://www.youtube.com/embed/${videoId}?disablekb=1&controls=0&showinfo=0&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&&autoplay=1&loop=1&mute=1&playlist=${videoId}`;
	};

	useEffect(() => {
		fetchProjectData();
	}, [fetchProjectData]);

	return (
		<div className="page-project">
			<div className="project">
				<AppNavbar />

				<div className="clients-section">
					<div className="clients-section__body">
						<div className="clients-section__wrapper">
							<p className="clients-section__title">Our Clients</p>

							<p className="clients-section__sub-title">{projectData?.title}</p>

							<p className="clients-section__description">{projectData?.information}</p>

							<AppPagination className="app-pagination" totalPageNumber={projectData?.medias.length} currentPage={videoIndex} onHandleNext={onHandlePlayNextVideo} onHandleBack={onHandlePlayBackVideo} />
						</div>

						{projectData?.medias && projectData.medias.length > 0 && (
							<>
								{projectData.medias[videoIndex].mediaType === "VIDEO" ? (
									<div className="clients-section__youtube">
										<iframe
											key={videoKey}
											className="video"
											width="560"
											height="315"
											src={getYouTubeEmbedUrl(projectData.medias[videoIndex].mediaPath)}
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen
											title={`YouTube video ${videoIndex}`}
										/>
									</div>
								) : (
									<img key={videoKey} src={projectData.medias[videoIndex].mediaPath} alt="" className="image" />
								)}
							</>
						)}
					</div>
				</div>

				<ProjectShowCaseInfo client={projectData?.title} type={projectData?.platform} whatWeDidItems={projectData?.services} genres={projectData?.genres} description={projectData?.description} />

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageProjectShowCase;
