import atfLogoIcon from "assets/images/components/app-footer/atf-grey-logo.svg";
import facebookIcon from "assets/images/components/app-footer/facebook-icon.svg";
import twitterIcon from "assets/images/components/app-footer/twitter-icon.svg";
import linkedinIcon from "assets/images/components/app-footer/linkedin-icon.svg";

const AppFooter = () => {
	return (
		<div className="app-footer">
			<div className="footer">
				<div className="footer__logo">
					<img src={atfLogoIcon} alt="atf logo" />
				</div>

				<div className="footer__wrapper">
					<div className="address-container">
						<p className="address-container__title">Malaysia (HQ)</p>
						<p className="address-container__description">Tower 2, Puchong Financial Corporate Centre, Unit 8F-1C, Jalan Puteri 1/2, Bandar Puteri Puchong, 47100 Puchong, Selangor</p>
					</div>

					<div className="address-container">
						<p className="address-container__title">Thailand</p>
						<p className="address-container__description">Room No. 1814, 18th Floor, Sathorn Thani Tower II, 92/53 North Sathon Road, Silom, Bang Rak, Bangkok 10500</p>
					</div>

					<div className="address-container">
						<p className="address-container__title">Singapore</p>
						<p className="address-container__description">Level 49 & 50 One Raffles Quay, Singapore 048583</p>
					</div>

					<div className="address-container">
						<p className="address-container__title">Email </p>
						<p className="address-container__description">admin@altecflex.com</p>
					</div>

					<div className="address-container">
						<p className="address-container__title">Connect With us </p>

						<div className="address-container__wrapper">
							<button className="address-container__box" onClick={() => {}}>
								<img className="address-container__icon" src={facebookIcon} alt={"facebookIcon"} />
							</button>

							<button className="address-container__box" onClick={() => {}}>
								<img className="address-container__icon" src={twitterIcon} alt={"twitterIcon"} />
							</button>

							<button className="address-container__box" onClick={() => {}}>
								<img className="address-container__icon" src={linkedinIcon} alt={"linkedinIcon"} />
							</button>
						</div>
					</div>
				</div>

				<div className="footer__divider" />

				<div className="footer__section">
					<p className="footer__text">Copyright © {new Date().getFullYear()} atf | Altecflex Sdn Bhd. All Rights Reserved.</p>

					<div className="footer__navigation">
						<button className="footer__link" onClick={() => {}}>
							<p className="footer__text">Terms of Use</p>
						</button>

						<div className="footer__seperator" />

						<button className="footer__link" onClick={() => {}}>
							<p className="footer__text">Privacy Policy</p>
						</button>

						<div className="footer__seperator" />

						<button className="footer__link" onClick={() => {}}>
							<p className="footer__text">Cookie Policy</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppFooter;
