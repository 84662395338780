import { createBrowserRouter, RouteObject } from "react-router-dom";
import pathnames from "routes/pathnames";
import PageHome from "pages/page-home";
import PageWhoWeAre from "pages/page-who-we-are";
import PageService from "pages/page-service";
import PageChatWithUs from "pages/page-chat-with-us";
import PageProduct from "pages/page-product";
import ScrollToTop from "common/scroll-to-top";
import PageAllArticles from "pages/page-all-articles";
import PageProjectShowCase from "pages/page-project-show-case";
import PageArticle from "pages/page-article";
import PageCareer from "pages/page-career";
import PageConnectWithUs from "pages/page-connect-with-us";
import PageAllProducts from "pages/page-all-products";

const routes = [
	{
		path: pathnames.PageHome,
		element: PageHome,
	},
	{
		path: pathnames.PageWhoWeAre,
		element: PageWhoWeAre,
	},
	{
		path: pathnames.PageWhoWeAreJoin,
		element: PageWhoWeAre,
	},
	{
		path: pathnames.PageService,
		element: PageService,
	},
	{
		path: pathnames.PageChatWithUs,
		element: PageChatWithUs,
	},
	{
		path: pathnames.PageProduct,
		element: PageProduct,
	},
	{
		path: pathnames.PageAllArticles,
		element: PageAllArticles,
	},
	{
		path: `${pathnames.PageProjectShowCase}/:id`,
		element: PageProjectShowCase,
	},
	{
		path: pathnames.PageConnectWithUs,
		element: PageConnectWithUs,
	},
	{
		path: `${pathnames.PageArticle}/:id`,
		element: PageArticle,
	},
	{
		path: pathnames.PageCareer,
		element: PageCareer,
	},
	{
		path: pathnames.PageAllProducts,
		element: PageAllProducts,
	},
];

export default routes;

const enrichedRouteObject: RouteObject[] = routes.map(({ element, ...res }) => {
	const Page = element;
	const routeObject: RouteObject = res;
	routeObject.element = (
		<ScrollToTop>
			<Page />
		</ScrollToTop>
	);
	return routeObject;
});

export const router = createBrowserRouter(enrichedRouteObject);
