const END_POINT_PATH = {
	SOLUTIONS: {
		SOLUTIONS: "solution/search",
	},
	CLIENTS: {
		CLIENTS: "/client/list",
		CLIENT: "/client/",
	},
	ARTICLE: {
		ARTICLE_DETAILS: "/article/",
		SIMILAR_ARTICLE: "/article/category/",
		OUR_ARTICLES: "/article/search/",
		CATEGORY_TITLE: "/article/categorySearch/categoryTitle",
	},
	FAQ: {
		FAQ: "/faq/list",
	},
	SERVICES: {
		SERVICES: "/solution/category/",
	},
	PARTNER: {
		PARTNER: "/partner/list",
	},
	CAREER: {
		CAREER: "/career/search/",
		FORM: "/career/career-form",
		RESOURCE: "/resource/find/customer/123/template/template_1",
	},
	BRANCH: {
		BRANCH: "/branch/retrieve",
	},
	DROPDOWN: {
		RETRIEVE_CATEGORY: "/dropdown/retrieve/",
	},
};

export default END_POINT_PATH;
