//library
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ArticleDetailsDto, ArticleDto } from "dto/components/app-article-info/app-article-dto";

// components
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppArticleCard from "components/app-article-card";
import AppArticleInfo from "components/app-article-info";
import AppArticleDetails from "components/app-article-details";

// assets
import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";

const PageArticle = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [articleDetails, setArticleDetails] = useState<ArticleDetailsDto>();
	const [similarArticles, setSimilarArticles] = useState<ArticleDto[]>([]);
	const newsImage = resolutionImg;

	const onHandleGetSimilarArticles = useCallback(async (category: string) => {
		let response = null;

		try {
			response = await api.get.similarArticle.article(category);

			setSimilarArticles(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetArticleDetails = useCallback(async () => {
		let response = null;

		if (!id) return;

		try {
			response = await api.get.articleDetails.article(id);

			setArticleDetails(response.data.data);

			onHandleGetSimilarArticles(response.data.data.category);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, [id, onHandleGetSimilarArticles]);

	useEffect(() => {
		onHandleGetArticleDetails();
	}, [onHandleGetArticleDetails]);

	return (
		<div className="page-article">
			<div className="article">
				<AppNavbar />

				<HeaderCard backgroundImage={newsImage} title={articleDetails?.title} customClass="align-end" />

				<div className="content">
					<div className="content__left">
						<AppArticleInfo publicationDate={articleDetails?.date} category={articleDetails?.category} tableOfContent={articleDetails?.tableOfContent || []} attachments={articleDetails?.attachments || []} />
					</div>

					<div className="content__right">
						<AppArticleDetails content={articleDetails?.content || []} />
					</div>
				</div>

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">Similar News</p>

						{/* prettier-ignore */}
						<button className="view-button" onClick={() => {navigate(pathnames.PageAllArticles)}}>
							<p className="view-button__text">View All News</p>
						</button>
					</div>

					<div className="articles-section__wrapper">
						{similarArticles.slice(0, 3).map((o, i) => {
							const articleLink = `${pathnames.PageArticle}/${o.id}`;

							return <AppArticleCard title={o.title} description={o.category} image={o.thumbnail} key={i} link={articleLink} />;
						})}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageArticle;
