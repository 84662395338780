// libraries
import React, { memo, useImperativeHandle, useState } from "react";
import { Modal } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

// dto
import AppSubscribeCardDto from "dto/components/app-subscribe-card-dto";
import { ImperativeHandleAppSubscribeCardDto } from "dto/components/app-subscribe-card-dto";

// components
import AppInput from "components/app-input";
import AppCheckbox from "components/app-checkbox";

// assets
import WhiteCloseIcon from "assets/images/white-close-icon.svg";
import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";

const AppSubscribeCard = React.forwardRef<ImperativeHandleAppSubscribeCardDto, AppSubscribeCardDto>((props, ref) => {
	const [isVisible, setIsVisible] = useState(false);
	const subscribeImage = resolutionImg;
	const subscribeTitle = "Subscribe to Our Newsletter";
	const subscribeDescription = "We know you love savings. Sign up for more! Don't miss out on our latest topics and tips which can help you save money! Plus, be the first to get updated on any special promotions and offers.";

	//prettier-ignore
	const formik = useFormik({
		initialValues: { email: "", terms: false },
		validationSchema: yup.object({
			email: yup.string().email("Invalid email address").required("Required"),
			terms: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
		}),
		onSubmit: (values) => {
			console.log("handle submit")
		},
	});

	const onHandleDismiss = () => {
		setIsVisible(false);
	};

	const onHandleShow = () => {
		formik.resetForm();
		setIsVisible(true);
	};

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-subscribe-card" }} open={isVisible} aria-labelledby="app-subscribe-card" aria-describedby="app-subscribe-card" onClose={onHandleDismiss}>
			<div className="subscribe-card">
				<div className="subscribe-card__header">
					<img className="subscribe-card__close-btn" src={WhiteCloseIcon} alt="icon" onClick={onHandleDismiss} />
				</div>

				<div className="subscribe-card__container">
					<img className="subscribe-card__img" src={subscribeImage} alt="icon" />

					<div className="subscribe-card__wrapper">
						<div className="subscribe-card__title">{subscribeTitle}</div>

						<div className="subscribe-card__description">
							<p>{subscribeDescription}</p>
						</div>

						<form onSubmit={formik.handleSubmit}>
							<div>
								{/*prettier-ignore*/}
								<AppInput type="email" name="email" label="Email"  placeholder="Enter your email here..." onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} />

								<div className="terms-section">
									<AppCheckbox required name="terms" value={formik.values.terms} onChange={(name, value) => formik.setFieldValue(name, value)} error={formik.errors.terms} touched={formik.touched.terms}>
										<p className="submit-container__text" id="agreeTnc">
											I agree with <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>
										</p>
									</AppCheckbox>

									<button type="submit" className="subscribe-button-section">
										Subscribe Now
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Modal>
	);
});

export default memo(AppSubscribeCard);
