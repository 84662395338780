//library
import { useCallback, useMemo, useState } from "react";

//components
import AppNavbar from "components/app-navbar";
import AppButton from "components/app-button";
import AppFooter from "components/app-footer";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppPagination from "components/app-pagination";
import AppServicesCard from "components/app-services-card";
import AppBrochureCard from "components/app-brochure-card";

// assets
import superappImg from "assets/images/pages/page-home/thumb-superapp.jpg";
import squadImg from "assets/images/pages/page-home/thumb-delivery-squad.jpg";
import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";

const PageAllProducts = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const [currentBrochurePage, setCurrentBrochurePage] = useState(0);
	const [currentSolutionPage, setCurrentSolutionPage] = useState(0);
	const [selectedButton, setSelectedButton] = useState("SERVICES");

	const newsImage = resolutionImg;
	const buttonOptions = ["SERVICES", "FSI SOLUTIONS", "OTHER SOLUTIONS", "ONECONNECT", "REDIS", "MONGODB"];

	//prettier-ignore
	const solutionBrochureData = useMemo(() => [
		{ id: 1, image: resolutionImg, title: "Global Bulk Scaling", category: "SERVICES", link: "" },
		{ id: 2, image: squadImg, title: "IT Consulting", category: "SERVICES", link: "" },
		{ id: 3, image: superappImg, title: "Delivery Squad", category: "SERVICES", link: "" },
		{ id: 4, image: resolutionImg, title: "Offshore Dev Center", category: "SERVICES", link: "" },
		{ id: 5, image: superappImg, title: "System Development", category: "SERVICES", link: "" },
		{ id: 6, image: squadImg, title: "Super App Ecosystem", category: "SOLUTIONS", link: "" },
		{ id: 7, image: squadImg, title: "Budget-Friendly Outsourcing (VN, IN)", category: "SOLUTIONS", link: "" },
		{ id: 8, image: superappImg, title: "PMO Services", category: "SOLUTIONS", link: "" },
		{ id: 9, image: superappImg, title: "PMO Services", category: "SOLUTIONS", link: "" },
		{ id: 10, image: superappImg, title: "DevOps Solutions", category: "SOLUTIONS", link: "" },
	], []);

	//prettier-ignore
	const serviceBrochureData = useMemo(() => [
		{ id: 1, image: resolutionImg, title: "Global Bulk Scaling", category: "SERVICES", link: "" },
		{ id: 2, image: squadImg, title: "IT Consulting", category: "SERVICES", link: "" },
		{ id: 3, image: superappImg, title: "Delivery Squad", category: "SERVICES", link: "" },
		{ id: 4, image: resolutionImg, title: "Offshore Dev Center", category: "SERVICES", link: "" },
		{ id: 5, image: superappImg, title: "System Development", category: "SERVICES", link: "" },
		{ id: 6, image: squadImg, title: "Super App Ecosystem", category: "SOLUTIONS", link: "" },
		{ id: 7, image: squadImg, title: "Budget-Friendly Outsourcing", category: "SOLUTIONS", link: "" },
		{ id: 8, image: superappImg, title: "PMO Services", category: "SOLUTIONS", link: "" },
		{ id: 9, image: superappImg, title: "PMO Services", category: "SOLUTIONS", link: "" },
		{ id: 10, image: superappImg, title: "DevOps Solutions", category: "SOLUTIONS", link: "" },
	], []);

	//prettier-ignore
	const serviceData = useMemo(() => [
		{ id: 1, image: resolutionImg, title: "Global Bulk Scaling", category: "SERVICES" },
		{ id: 2, image: squadImg, title: "IT Consulting", category: "SERVICES" },
		{ id: 3, image: superappImg, title: "Delivery Squad", category: "SERVICES" },
		{ id: 4, image: resolutionImg, title: "Offshore Dev Center", category: "SERVICES" },
		{ id: 5, image: superappImg, title: "System Development", category: "SERVICES" },
		{ id: 6, image: squadImg, title: "Super App Ecosystem", category: "SERVICES" },
		{ id: 7, image: squadImg, title: "Budget-Friendly Outsourcing (VN, IN)", category: "SERVICES" },
		{ id: 8, image: superappImg, title: "PMO Services", category: "SERVICES" },
		{ id: 9, image: superappImg, title: "PMO Services", category: "SERVICES" },
		{ id: 10, image: superappImg, title: "DevOps Solutions", category: "SERVICES" },
		{ id: 11, image: superappImg, title: "Financial Advisory", category: "FSI SOLUTIONS" },
		{ id: 12, image: squadImg, title: "Super App", category: "OTHER SOLUTIONS" },
		{ id: 13, image: resolutionImg, title: "Customer 360", category: "ONECONNECT" },
		{ id: 14, image: superappImg, title: "Redis query Engine", category: "REDIS" },
		{ id: 15, image: squadImg, title: "Mongo DB", category: "MONGODB" },
	], []);

	const filteredServices = useMemo(() => serviceData.filter((service) => service.category === selectedButton), [selectedButton, serviceData]);

	const totalPageNumber = useMemo(() => Math.ceil(filteredServices.length / 9), [filteredServices]);

	const paginatedServices = useMemo(() => filteredServices.slice(currentPage * 9, (currentPage + 1) * 9), [currentPage, filteredServices]);

	const totalBrochurePageNumber = useMemo(() => Math.ceil(serviceBrochureData.length / 4), [serviceBrochureData]);

	const paginatedBrochure = useMemo(() => serviceBrochureData.slice(currentBrochurePage * 4, (currentBrochurePage + 1) * 4), [currentBrochurePage, serviceBrochureData]);

	const totalSolutionPageNumber = useMemo(() => Math.ceil(solutionBrochureData.length / 4), [solutionBrochureData]);

	const paginatedSolution = useMemo(() => solutionBrochureData.slice(currentSolutionPage * 4, (currentSolutionPage + 1) * 4), [currentSolutionPage, solutionBrochureData]);

	const onSelect = (option: string) => {
		setSelectedButton(option);
		setCurrentPage(0);
	};

	const onHandleNext = useCallback(() => {
		if (currentPage !== totalPageNumber - 1) {
			setCurrentPage((prev) => ++prev);
		}
	}, [currentPage, totalPageNumber]);

	const onHandleBack = useCallback(() => {
		if (currentPage !== 0) {
			setCurrentPage((prev) => --prev);
		}
	}, [currentPage]);

	const onHandleBrochureNext = useCallback(() => {
		if (currentBrochurePage !== totalBrochurePageNumber - 1) {
			setCurrentBrochurePage((prev) => ++prev);
		}
	}, [currentBrochurePage, totalBrochurePageNumber]);

	const onHandleBrochureBack = useCallback(() => {
		if (currentBrochurePage !== 0) {
			setCurrentBrochurePage((prev) => --prev);
		}
	}, [currentBrochurePage]);

	const onHandleSolutionNext = useCallback(() => {
		if (currentSolutionPage !== totalSolutionPageNumber - 1) {
			setCurrentSolutionPage((prev) => ++prev);
		}
	}, [currentSolutionPage, totalSolutionPageNumber]);

	const onHandleSolutionBack = useCallback(() => {
		if (currentSolutionPage !== 0) {
			setCurrentSolutionPage((prev) => --prev);
		}
	}, [currentSolutionPage]);

	return (
		<div className="page-all-products">
			<div className="products">
				<AppNavbar />

				<HeaderCard backgroundImage={newsImage} title={"Grow your Business with us"} />

				<div className="button-section">
					{buttonOptions.map((option) => (
						<AppButton className="button-section__button" key={option} label={option} outline onClick={() => onSelect(option)} />
					))}
				</div>

				<div className="services-section">
					<p className="services-section__title">Services</p>

					<div className="services-section__after">
						<div className="services-section__header">
							<p className="services-section__description">
								If you are looking for a reliable technology service provider with whom you share your mind and get the best digital roadmap you’ve been looking for, we are here. With years of experiences in
								implementing all scale of innovation platforms, we’ve mastered the skills of studying the market for you and helping you cope up with the latest trends that’ll take your business to the next level.
							</p>

							{totalPageNumber > 1 && <AppPagination totalPageNumber={totalPageNumber} currentPage={currentPage} onHandleNext={() => onHandleNext()} onHandleBack={() => onHandleBack()} />}
						</div>

						<div className="services-section__wrapper">
							{paginatedServices.map((o, i) => (
								<AppServicesCard title={o.title} image={o.image} key={i} />
							))}
						</div>
					</div>
				</div>

				<div className="services-and-solutions-section">
					<p className="services-and-solutions-section__title">Our Services & Solutions E-Brochures</p>

					<div className="services-and-solutions-section__header">
						<p className="services-and-solutions-section__description">
							If you are looking for a reliable technology service provider with whom you share your mind and get the best digital roadmap you’ve been looking for, we are here. With years of experiences in
							implementing all scale of innovation platforms, we’ve mastered the skills of studying the market for you and helping you cope up with the latest trends that’ll take your business to the next level.
						</p>
					</div>

					{/* prettier-ignore */}
					<div className="service-brochure-section">
						<p className="service-brochure-section__title">Services E-Brochure </p>

						{totalSolutionPageNumber > 1 && <AppPagination totalPageNumber={totalBrochurePageNumber} currentPage={currentBrochurePage} onHandleNext={() => onHandleBrochureNext()} onHandleBack={() => onHandleBrochureBack()} />}
					</div>

					<div className="service-brochure-section__wrapper">
						{paginatedBrochure.map((o, i) => (
							<div className="service-brochure-section__box">
								<AppBrochureCard title={o.title} image={o.image} link={o.link} key={i} />
							</div>
						))}
					</div>

					{/* prettier-ignore */}
					<div className="service-brochure-section">
						<p className="service-brochure-section__title">Solutions E-Brochure </p>
						
						{totalSolutionPageNumber > 1 && <AppPagination totalPageNumber={totalSolutionPageNumber} currentPage={currentSolutionPage} onHandleNext={() => onHandleSolutionNext()} onHandleBack={() => onHandleSolutionBack()} />}
					</div>

					<div className="service-brochure-section__wrapper">
						{paginatedSolution.map((o, i) => (
							<div className="service-brochure-section__box">
								<AppBrochureCard title={o.title} image={o.image} link={o.link} key={i} />
							</div>
						))}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageAllProducts;
