import { AppPositionCardDto } from "dto/components/app-position-card-dto";

const AppPositionCard = ({ title, jobType, description, onHandleApplyNow }: AppPositionCardDto) => {
	return (
		<div className="app-position-card">
			<div className="position-card">
				<p className="position-card__title">{title}</p>

				<p className="position-card__description">
					Job Types: <span className="job-type">{jobType}</span>
				</p>

				<p className="position-card__description">{description}</p>

				<div className="position-card__section">
					<button className="apply-button" onClick={() => onHandleApplyNow(title, jobType)}>
						<p className="apply-button__text">Apply Now</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default AppPositionCard;
