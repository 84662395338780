import { useNavigate } from "react-router-dom";
import AppBrochureCardDto from "dto/components/app-brochure-card-dto";
import AppButton from "components/app-button";

const AppBrochureCard = ({ title, image, link }: AppBrochureCardDto) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(link);
	};

	return (
		<div className="app-brochure-card">
			<div className="brochure-card">
				<div className="brochure-card__container" onClick={handleClick}>
					<div className="brochure-card__image" style={{ backgroundImage: `url(${image})` }} />
				</div>

				<p className="brochure-card__title">{title}</p>

				<AppButton className="brochure-card__button"label="Download E-Brochure" outline onClick={handleClick} />
			</div>
		</div>
	);
};

export default AppBrochureCard;
