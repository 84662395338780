import { useCallback, useEffect, useState } from "react";
import pathnames from "routes/pathnames";
import { Link, useParams } from "react-router-dom";
import AnimatedNumbers from "react-animated-numbers";
import { SpinnerCircularFixed } from "spinners-react";

// component
import AppFooter from "../components/app-footer";
import AppDrawer from "components/pages/menu/app-drawer";
import AppSwiper from "components/pages/page-home/app-swiper";

// assest
import logo from "assets/images/atf-logo.png";
import award from "assets/images/pages/page-who-we-are/award.png";
import businessman from "assets/images/pages/page-who-we-are/thumb-management.jpg";
import group from "assets/images/pages/page-who-we-are/thumb-transformation.jpg";
import puzzle from "assets/images/pages/page-who-we-are/thumb-implementations.jpg";
import flow from "assets/images/pages/page-who-we-are/thumb-fintech.jpg";
import AppMap from "components/pages/page-who-we-are/app-map";

import circleArrowIcon from "assets/images/circle-arrow-white.png";
import circleArrowBlueIcon from "assets/images/circle-arrow-blue.png";
import sentIcon from "assets/images/pages/page-chat-with-us/ico-Sent.png";

import passionIcon from "assets/images/pages/page-who-we-are/who-we-are_why-altecflex/ico-Passion & Commitment.png";
import honestyIcon from "assets/images/pages/page-who-we-are/who-we-are_why-altecflex/ico-Honesty & Openness.png";
import dedicatedIcon from "assets/images/pages/page-who-we-are/who-we-are_why-altecflex/ico-Dedicated Team.png";
import practicalIcon from "assets/images/pages/page-who-we-are/who-we-are_why-altecflex/ico-Practical Approach.png";
import cv01Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue01.png";
import cv02Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue02.png";
import cv03Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue03.png";
import cv04Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue04.png";
import cv05Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue05.png";
import whyatf01Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf01.png";
import whyatf02Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf02.png";
import whyatf03Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf03.png";
import whyatf04Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf04.png";
import whyatf05Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf05.png";
import whyatf06Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf06.png";
import whyatf07Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf07.png";
import whyatf08Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf08.png";
import exclamation from "assets/images/pages/page-chat-with-us/exclamation.png";
import ySlashIcon from "assets/images/pages/page-who-we-are/yellow-slash.png";
import uploadIcon from "assets/images/pages/page-who-we-are/upload.png";

import { FormControl, Select, MenuItem, TextField, Button } from "@mui/material";

const PageWhoWeAre = () => {
	const { link } = useParams();

	const [services, setServices] = useState({ atf: link !== "join", join: link === "join" });
	const [success] = useState(false);

	const [joinSelect, setJoinSelect] = useState(false);

	const onSelect = (s: string) => {
		if (s === "atf") {
			setServices({ atf: true, join: false });
		} else {
			setServices({ atf: false, join: true });
			setJoinSelect(true);
		}
	};

	const onHandleScrollAnimation = useCallback(() => {
		const w = document.getElementById("root");
		if (!w) return;
		const pageTop = w?.scrollTop;
		const ani1 = document.getElementById("animation-1");
		const ani2 = document.getElementById("animation-2");
		const ani3 = document.getElementById("animation-3");
		const ani4 = document.getElementById("animation-4");
		const ani5 = document.getElementById("animation-5");
		const pageBottom = pageTop + window.innerHeight;

		const onHandleAddClass = (item: any, i: number) => {
			setTimeout(() => {
				item.classList.add("fadeInAndUp");
			}, 100 * i);
		};

		if (ani1) {
			if (pageTop - 500 > ani1.offsetHeight) {
				ani1.getElementsByClassName("reason-why__title")[0].classList.add("fadeInAndUp");
				ani1.getElementsByClassName("reason-why__text")[0].classList.add("fadeInAndUp");
				ani1.getElementsByClassName("reason-why__items-row")[0].classList.add("fadeInAndUp");
			}
		}
		if (ani2) {
			if (pageTop - 700 > ani2.offsetHeight) {
				ani2?.getElementsByClassName("award")[0]?.classList?.add("fadeInAndRight");
				ani2?.getElementsByClassName("mission")[0]?.classList?.add("fadeInAndLeft");
				ani2?.getElementsByClassName("mobile-award")[0]?.classList?.add("fadeInAndRight");
			}
		}
		if (ani3) {
			if (pageTop - 1000 > ani3.offsetHeight) {
				ani3?.getElementsByClassName("extraordinary__detail__moveTop")[0]?.classList?.add("fadeInAndDown");
				ani3?.getElementsByClassName("extraordinary__detail__moveTop")[1]?.classList?.add("fadeInAndDown");
				ani3?.getElementsByClassName("extraordinary__detail__moveBottom")[0]?.classList?.add("fadeInAndUp");
				ani3?.getElementsByClassName("extraordinary__detail__moveBottom")[1]?.classList?.add("fadeInAndUp");
			}
		}
		if (ani4) {
			if (pageBottom - 3400 > ani4.offsetTop + ani4.offsetTop / 3) {
				const list = ani4.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					const item = list[i];
					onHandleAddClass(item, i);
				}
			}
		}
		if (ani5) {
			if (pageBottom > ani5.offsetTop + ani5.offsetTop / 3) {
				const list = ani5.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					const item = list[i];
					onHandleAddClass(item, i);
				}
			}
		}
	}, []);

	useEffect(() => {
		setServices({ atf: link !== "join", join: link === "join" });
	}, [link]);

	useEffect(() => {
		const w = document.getElementById("root");
		if (w) {
			w.addEventListener("scroll", onHandleScrollAnimation, { capture: true });
			return () => w.removeEventListener("scroll", onHandleScrollAnimation);
		}
	}, [onHandleScrollAnimation]);

	const defaultCountries = {
		malaysia: true,
		singapore: false,
		thailand: false,
		cambodia: false,
		vietnam: false,
		korea: false,
	};

	const [countries, setCountries] = useState(defaultCountries);

	const onCountriesSelect = (event: any) => {
		const value = event.currentTarget.value;
		if (value === "MY") {
			setCountries({
				malaysia: true,
				singapore: false,
				thailand: false,
				cambodia: false,
				vietnam: false,
				korea: false,
			});
		} else if (value === "SG") {
			setCountries({
				malaysia: false,
				singapore: true,
				thailand: false,
				cambodia: false,
				vietnam: false,
				korea: false,
			});
		} else if (value === "TH") {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: true,
				cambodia: false,
				vietnam: false,
				korea: false,
			});
		} else if (value === "CM") {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: false,
				cambodia: true,
				vietnam: false,
				korea: false,
			});
		} else if (value === "VN") {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: false,
				cambodia: false,
				vietnam: true,
				korea: false,
			});
		} else if (value === "KR") {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: false,
				cambodia: false,
				vietnam: false,
				korea: true,
			});
		} else {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: false,
				cambodia: false,
				vietnam: false,
				korea: false,
			});
		}
	};

	const [positionList] = useState<any>([]);
	// let queryParams = {
	// 	key: "POSITION",
	// 	length: 100,
	// 	order: "desc",
	// 	page: 0,
	// 	sort: "created_at",
	// };

	// useEffect(() => {
	// 	const listing = async () => {
	// 		try {
	// 			fetch(process.env.REACT_APP_API_URL + API.ENDPOINT_PATH.GET_LISTING + "?key=POSITION&length=100&order=desc&page=0&sort=created_at", {
	// 				method: "GET",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Accept: "*/*",
	// 					"Accept-language": "en",
	// 				},
	// 			})
	// 				.then((response) => response.json())
	// 				.then((json) => {
	// 					let result = json.result;
	// 					setPositionList(result);
	// 				});
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	};
	// 	listing();
	// }, []);

	const inputField = {
		name: {
			value: "",
			error: false,
			placeholder: "",
			regex: /^[a-zA-Z0-9 ./-]*$/,
			id: "name",
		},
		mobile: {
			value: "",
			error: false,
			placeholder: "",
			regex: /^[0-9]*$/,
			id: "mobile",
		},
		email: {
			value: "",
			error: false,
			placeholder: "",
			regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			id: "email",
		},
		position: {
			value: "",
			error: false,
			placeholder: "",
			regex: /^(?!\s*$).+/,
			id: "position",
		},
		uploadCV: {
			value: "",
			error: false,
			placeholder: "",
			id: "uploadCV",
		},
	};

	const [input, setInput] = useState(inputField);

	const onHandleOnChange = (event: any) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;
		setInput({
			...input,
			[name]: {
				value: value,
			},
		});
	};

	const onHandleOnSelect = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setInput({
			...input,
			[name]: {
				value: value,
			},
		});
	};

	const [exceedFileSize, setExceedFileSize] = useState("");
	const [inputFileName, setInputFileName] = useState("");

	const onHandleFileChange = (event: any) => {
		let inputFile = event.target.files[0];
		const name = event.currentTarget.name;
		setInputFileName(inputFile.name);
		let _size = inputFile.size;
		let fSExt = ["Bytes", "KB", "MB", "GB"],
			i = 0;
		while (_size > 900) {
			_size /= 1024;
			i++;
		}
		let num = Math.round(_size * 100) / 100;
		let exactSize = num + " " + fSExt[i];
		console.log("exactSize", exactSize);
		if (i > 2) {
			// GB
			setExceedFileSize("Uploaded file or image cannot exceed 4 MB");
		} else if (i === 2 && num > 4) {
			// exceed 500 MB
			setExceedFileSize("Uploaded file or image cannot exceed 4 MB");
		} else {
			setInput({
				...input,
				[name]: {
					value: inputFile,
				},
			});
			setExceedFileSize("");
		}
	};

	const [disableBtn, setDisableBtn] = useState(false);

	const [checkName, setCheckName] = useState(false);
	const [checkMobile, setCheckMobile] = useState(false);
	const [checkEmail, setCheckEmail] = useState(false);
	const [checkPosition, setCheckPosition] = useState(false);
	const [checkUploadCV, setCheckUploadCV] = useState(false);

	const checkValidation = () => {
		let nameCheck = inputField.name.regex.test(input.name.value) === true;
		let mobileCheck = inputField.mobile.regex.test(input.mobile.value) === true;
		let emailCheck = inputField.email.regex.test(input.email.value) === true;
		let positionCheck = inputField.position.regex.test(input.position.value) === true;

		if ((input.name.value.trim() !== "" && nameCheck) === false) {
			setCheckName(true);
		} else {
			setCheckName(false);
		}
		if ((input.mobile.value.trim() !== "" && mobileCheck) === false) {
			setCheckMobile(true);
		} else {
			setCheckMobile(false);
		}
		if (emailCheck === false) {
			setCheckEmail(true);
		} else {
			setCheckEmail(false);
		}
		if (positionCheck === false) {
			setCheckPosition(true);
		} else {
			setCheckPosition(false);
		}
		if ((input.uploadCV.value !== "") === false) {
			setCheckUploadCV(true);
		} else {
			setCheckUploadCV(false);
		}
	};

	const onSubmit = () => {
		checkValidation();
		let nameCheck = inputField.name.regex.test(input.name.value) === true;
		let mobileCheck = inputField.mobile.regex.test(input.mobile.value) === true;
		let emailCheck = inputField.email.regex.test(input.email.value) === true;
		let positionCheck = inputField.position.regex.test(input.position.value) === true;

		if (nameCheck === false || mobileCheck === false || emailCheck === false || positionCheck === false || input.uploadCV.value === "" || exceedFileSize !== "") {
			return;
		} else {
			setDisableBtn(true);
			setTimeout(() => {
				setDisableBtn(false);
			}, 6000);
		}

		const formData = new FormData();
		formData.append("name", input.name.value.trim());
		formData.append("mobileNumber", input.mobile.value.trim());
		formData.append("email", input.email.value.trim());
		formData.append("position", input.position.value);
		formData.append("resumes", input.uploadCV.value);

		// fetch(process.env.REACT_APP_API_URL + API.ENDPOINT_PATH.POST_JOB, {
		// 	method: "POST",
		// 	headers: {
		// 		Accept: "*/*",
		// 		// "Content-Type": "application/json",
		// 		// "Content-Type": "multipart/form-data",
		// 	},
		// 	body: formData,
		// })
		// 	.then(function (response) {
		// 		return response.json();
		// 	})
		// 	.then(function (data) {
		// 		const response = data;
		// 		if (response.responseCode === 200) {
		// 			setSuccess(true);
		// 		} else {
		// 			setSuccess(false);
		// 		}
		// 	});
	};

	const onHandleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		if (value === "atf") {
			setServices({ atf: true, join: false });
		} else {
			setServices({ atf: false, join: true });
		}
		// const root = document.getElementById("root");
		// if (root) root.scrollTo(0, 0);
	};

	return (
		<div className="page-who-we-are">
			<div className="who-we-are">
				<div className="section-1">
					<div className="top-menu">
						<div className="img-size">
							<Link to={pathnames.PageHome}>
								<img src={logo} alt="logo" />
							</Link>
							<AppDrawer />
						</div>
					</div>
					<Link to={pathnames.PageChatWithUs} className="chat-container">
						<button className="chat-container__btn">
							<span className="icon"></span>
							<span className="showT">Chat with us</span>
						</button>
					</Link>
					<div className="section-1__main-img">
						<div className="center">
							<div className="content-container">
								<div className="content__title">
									<span>We are</span> <span className="content__title--highlight">IT Service Provider </span>
									<br />
									<span>from Malaysia</span>
								</div>
								<div className="content__description">
									<p>
										We are passionate about growing the local young talents, building the digital transformation capability for our clients’ business. We’ve helped to provide great exposure to our local
										professionals with opportunities working in worldwide projects and leading technologies.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-2">
					<div className="why-atf">
						<div className="content">
							<span className={services.atf ? "content__description active" : "content__description"} onClick={() => onSelect("atf")}>
								<span className="hoverT">/</span> Why Altecflex
							</span>
							<span className={services.join ? "content__description active" : "content__description"} onClick={() => onSelect("join")}>
								<span className="hoverT">/</span> Join the Team
							</span>
						</div>
						<div className="dropdown">
							<div className="dropdown__wrapper">
								<span className="slash">/</span>
								<select className="select-frame" onChange={onHandleSelect}>
									<option value="atf" selected={services.atf}>
										Why Altecflex
									</option>
									<option value="join" selected={services.join}>
										Join the Team
									</option>
								</select>
							</div>
						</div>
					</div>
					{services.atf && (
						<div className="section-2__container1">
							<div className="row">
								<div className="column">
									<div className="card__text">
										<p className="card__text--top">We have formed a working model and have established partnership with</p>
										{/* <p className="card__text--number">5</p> */}
										<div className="card__text--number">
											<AnimatedNumbers
												includeComma
												animateToNumber={5}
												configs={[
													{ mass: 1, tension: 220, friction: 100 },
													{ mass: 1, tension: 180, friction: 130 },
													{ mass: 1, tension: 280, friction: 90 },
													{ mass: 1, tension: 180, friction: 135 },
													{ mass: 1, tension: 260, friction: 100 },
													{ mass: 1, tension: 210, friction: 180 },
												]}
											></AnimatedNumbers>
										</div>
										<p className="card__text--detail">leading agencies</p>
										<p>to leverage on their talents database open for use of ATF</p>
									</div>
								</div>

								<div className="column">
									<div className="card__text">
										<p className="card__text--top">We have our own talents database with</p>
										{/* <p className="card__text--number">3000+</p> */}
										<div className="card__text--number">
											<AnimatedNumbers
												includeComma
												animateToNumber={3000}
												configs={[
													{ mass: 1, tension: 220, friction: 100 },
													{ mass: 1, tension: 180, friction: 130 },
													{ mass: 1, tension: 280, friction: 90 },
													{ mass: 1, tension: 180, friction: 135 },
													{ mass: 1, tension: 260, friction: 100 },
													{ mass: 1, tension: 210, friction: 180 },
												]}
											></AnimatedNumbers>
											{"+"}
										</div>
										<p className="card__text--detail">candidates</p>
										<p>and our internal recruitment team are capable to continuously identify the local experienced talents that are exploring for opportunities</p>
									</div>
								</div>

								<div className="column">
									<div className="card__text">
										<p className="card__text--top">We have</p>
										{/* <p className="card__text--number">80+</p> */}
										<div className="card__text--number">
											<AnimatedNumbers
												includeComma
												animateToNumber={100}
												configs={[
													{ mass: 1, tension: 220, friction: 100 },
													{ mass: 1, tension: 180, friction: 130 },
													{ mass: 1, tension: 280, friction: 90 },
													{ mass: 1, tension: 180, friction: 135 },
													{ mass: 1, tension: 260, friction: 100 },
													{ mass: 1, tension: 210, friction: 180 },
												]}
											></AnimatedNumbers>
											{"+"}
										</div>
										<p className="card__text--detail">permanent employees</p>
										<p>and continously expanding. Join the team and help our client become the next and best versions of themselves</p>
									</div>
								</div>
							</div>
						</div>
					)}
					{services.join && (
						<div className="section-2__container2">
							<div className="reason-why__title">
								<span>Why Altecflex</span>
							</div>
							<div className="reason-why__center">
								<div className="reason-why__divider"></div>
							</div>
							<div className="content-flex-center">
								{link !== "join" ? (
									<div className={link !== "join" && joinSelect ? "wrapper fadeInAndUp" : "wrapper"} id={link !== "join" && joinSelect ? "" : "animation-5"}>
										{/* id="animation-5" */}
										<div className={link !== "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Exposure to International Digital Transformation Initiatives</div>
											<div className="box__info">
												ATF serves multinational clients across region such as (Singapore, Philippines, Thailand, German) providing vast exposure towards different culture & collaboration experiences.
											</div>
											<div className="box__icon">
												<img src={whyatf01Icon} alt="whyatf01Icon" />
											</div>
										</div>
										<div className={link !== "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Individual Career Development</div>
											<div className="box__info">ATF provides tailor made career development to grow each individual towards their desired career path.</div>
											<div className="box__icon">
												<img src={whyatf02Icon} alt="whyatf02Icon" />
											</div>
										</div>
										<div className={link !== "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Enterprise Standard and Mindset </div>
											<div className="box__info">
												ATF grooms employees by making sure stakeholder possess the right mindset to match up with Enterprise Standard. “No Blame Culture” is one of the proven successful practice that we
												had followed all this time.
											</div>
											<div className="box__icon">
												<img src={whyatf03Icon} alt="whyatf03Icon" />
											</div>
										</div>
										<div className={link !== "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Worldwide Projects Across Industries</div>
											<div className="box__info">With ATF, you are given a chance to contribute and expand your knowledge throughout different digital industries (e.g. Fintech, Insurtech, ERP & etc)</div>
											<div className="box__icon">
												<img src={whyatf04Icon} alt="whyatf04Icon" />
											</div>
										</div>
										<div className={link !== "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Opportunity to Relocate Other Country</div>
											<div className="box__info">
												Provided we have few branches across SEA region, candidates that is adventurous will be offered a chance to be relocated to overseas to support client / maintain relationships.
											</div>
											<div className="box__icon">
												<img src={whyatf05Icon} alt="whyatf05Icon" />
											</div>
										</div>
										<div className={link !== "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Knowledge Sharing Culture</div>
											<div className="box__info">ATF will make sure new joiners is briefed and prepared before deploying into projects, knowledge sharing is a must-do.</div>
											<div className="box__icon">
												<img src={whyatf06Icon} alt="whyatf06Icon" />
											</div>
										</div>
										<div className={link !== "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Teamwork Delivery and Objective Driven</div>
											<div className="box__info">We work as a team player to ensure the delivery happens within timeline with minimal gap. In short, we got each others back.</div>
											<div className="box__icon">
												<img src={whyatf07Icon} alt="whyatf07Icon" />
											</div>
										</div>
										<div className={link !== "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Employee Benefits</div>
											<div className="box__info">We study and revise employee benefits time to time to match market standards, making sure our employees enjoy maximum benefits.</div>
											<div className="box__icon">
												<img src={whyatf08Icon} alt="whyatf08Icon" />
											</div>
										</div>
										<div className="box box--transparent"></div>
									</div>
								) : (
									<div className={link === "join" && joinSelect ? "wrapper fadeInAndUp" : "wrapper"} id={link === "join" && joinSelect ? "" : "animation-5"}>
										{/* id="animation-5" */}
										<div className={link === "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Exposure to International Digital Transformation Initiatives</div>
											<div className="box__info">
												ATF serves multinational clients across region such as (Singapore, Philippines, Thailand, German) providing vast exposure towards different culture & collaboration experiences.
											</div>
											<div className="box__icon">
												<img src={whyatf01Icon} alt="whyatf01Icon" />
											</div>
										</div>
										<div className={link === "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Individual Career Development</div>
											<div className="box__info">ATF provides tailor made career development to grow each individual towards their desired career path.</div>
											<div className="box__icon">
												<img src={whyatf02Icon} alt="whyatf02Icon" />
											</div>
										</div>
										<div className={link === "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Enterprise Standard and Mindset </div>
											<div className="box__info">
												ATF grooms employees by making sure stakeholder possess the right mindset to match up with Enterprise Standard. “No Blame Culture” is one of the proven successful practice that we
												had followed all this time.
											</div>
											<div className="box__icon">
												<img src={whyatf03Icon} alt="whyatf03Icon" />
											</div>
										</div>
										<div className={link === "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Worldwide Projects Across Industries</div>
											<div className="box__info">With ATF, you are given a chance to contribute and expand your knowledge throughout different digital industries (e.g. Fintech, Insurtech, ERP & etc)</div>
											<div className="box__icon">
												<img src={whyatf04Icon} alt="whyatf04Icon" />
											</div>
										</div>
										<div className={link === "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Opportunity to Relocate Other Country</div>
											<div className="box__info">
												Provided we have few branches across SEA region, candidates that is adventurous will be offered a chance to be relocated to overseas to support client / maintain relationships.
											</div>
											<div className="box__icon">
												<img src={whyatf05Icon} alt="whyatf05Icon" />
											</div>
										</div>
										<div className={link === "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Knowledge Sharing Culture</div>
											<div className="box__info">ATF will make sure new joiners is briefed and prepared before deploying into projects, knowledge sharing is a must-do.</div>
											<div className="box__icon">
												<img src={whyatf06Icon} alt="whyatf06Icon" />
											</div>
										</div>
										<div className={link === "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Teamwork Delivery and Objective Driven</div>
											<div className="box__info">We work as a team player to ensure the delivery happens within timeline with minimal gap. In short, we got each others back.</div>
											<div className="box__icon">
												<img src={whyatf07Icon} alt="whyatf07Icon" />
											</div>
										</div>
										<div className={link === "join" && joinSelect ? "box" : "box show-animation"}>
											<div className="box__title">Employee Benefits</div>
											<div className="box__info">We study and revise employee benefits time to time to match market standards, making sure our employees enjoy maximum benefits.</div>
											<div className="box__icon">
												<img src={whyatf08Icon} alt="whyatf08Icon" />
											</div>
										</div>
										<div className="box box--transparent"></div>
									</div>
								)}
							</div>
							<div className="wrap-swiper">
								{/* id="animation-5" */}
								<AppSwiper props={"join"}></AppSwiper>
							</div>
						</div>
					)}
				</div>
				{services.atf && (
					<>
						<div className="section-3">
							<div className="reason-why" id="animation-1">
								<div className="reason-why__title">
									<span>Why Altecflex</span>
								</div>
								<div className="reason-why__center">
									<div className="reason-why__divider"></div>
								</div>
								<div className="reason-why__text">
									<span>We are well-experienced IT professionals with younger minds and thinking processes.</span>
								</div>
								<div className="reason-why__items-row">
									<div className="reason-why__items-row--column">
										<img src={passionIcon} alt="rocket" />
										<p>Passion & Commitment</p>
									</div>
									<div className="reason-why__items-row--column">
										<img src={honestyIcon} alt="rocket" />
										<p>Honesty & Openness</p>
									</div>
									<div className="reason-why__items-row--column">
										<img src={dedicatedIcon} alt="rocket" />
										<p>Dedicated Team</p>
									</div>
									<div className="reason-why__items-row--column">
										<img src={practicalIcon} alt="rocket" />
										<p>Practical Approach</p>
									</div>
								</div>
							</div>
						</div>
						<div className="section-4">
							<div className="center" id="animation-2">
								<div className="award">
									<img src={award} alt="award" />
								</div>
								<div className="mission">
									<div className="mission--container">
										<span className="mission__slash">/</span>
										<p className="mission__title">Mission</p>
										<div className="middle">
											<div className="divider"></div>
										</div>
										<span className="mission__detail">
											Bring clarity to the digital priorities, strategies and opportunities within businesses or technologies trend, especially the upcoming 5G and 6G transitions. Help raising awareness on
											the importance of future digitalization and new norm transformation to achieve the exceptional results desire.
											<br />
											<br />
											Being the leading IT service provider based at Malaysia, as the center development and IT operation hub making the success to our clients on their worldwide businesses.
										</span>
									</div>
								</div>
								<div className="mobile-award">
									<img src={award} alt="award" />
								</div>
							</div>
						</div>
						<div className="section-5">
							<div className="extraordinary">
								<div className="extraordinary__text">
									<span className="extraordinary__text--slash">/</span>
									<p className="extraordinary__text--title">EXTRAORDINARY EXPERIENCE</p>
								</div>
								<div className="center">
									<div className="extraordinary__divider"></div>
								</div>
								<div className="center">
									<div className="extraordinary__detail" id="animation-3">
										<div className="extraordinary__detail__moveTop">
											<img src={businessman} alt="businessman" className="topLeftImg" />
											<div className="info">
												Management team with <span className="bold-text">10 to 30 years</span> of IT working experiences.
											</div>
										</div>
										<div className="extraordinary__detail__moveBottom">
											<img src={group} alt="group" className="topRightImg" />
											<div className="info">
												Talents of expertise as <span className="bold-text">one stop solution</span> for any digital transformation and use case implementation.
											</div>
										</div>
										<div className="extraordinary__detail__moveTop">
											<img src={puzzle} alt="puzzle" className="bottomLeftImg" />
											<div className="info">
												Large scale and complex <span className="bold-text">digital implementations</span>.
											</div>
										</div>
										<div className="extraordinary__detail__moveBottom">
											<img src={flow} alt="flow" className="bottomRightImg" />
											<div className="info">
												Digital resolution coverage across industries, inclusive <span className="bold-text">Fintech, Insuretech, eCommerce, Industry 4.0, Smart Technologies, Super App Development</span>{" "}
												etc.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="section-6">
							<div className="core-container">
								<div className="core-value__items-row" id="animation-4">
									<div className="core-value__items-row--column">
										<div className="wrapper">
											<p className="wrapper--slash">/</p>
											<p className="wrapper--text">Our Core Value</p>
										</div>
									</div>
									<div className="core-value__items-row--column">
										<div className="shadow-box">
											<div className="description">
												<span>Skills and knowledge with modern technology stacks</span>
											</div>
											<div className="img-pst">
												<img src={cv01Icon} alt="rocket" />
											</div>
										</div>
									</div>
									<div className="core-value__items-row--column">
										<div className="shadow-box">
											<div className="description">
												<span>Shared resources pool of 160+ consultants together with partner companies</span>
											</div>
											<div className="img-pst">
												<img src={cv02Icon} alt="rocket" />
											</div>
										</div>
									</div>
									<div className="core-value__items-row--column">
										<div className="shadow-box">
											<div className="description">
												<span>Our consultants are trained with working exposure to worldwide projects implementation</span>
											</div>
											<div className="img-pst">
												<img src={cv03Icon} alt="rocket" />
											</div>
										</div>
									</div>
									<div className="core-value__items-row--column">
										<div className="shadow-box">
											<div className="description">
												<span>Culture with knowledge sharing and thinking process focus</span>
											</div>
											<div className="img-pst">
												<img src={cv04Icon} alt="rocket" />
											</div>
										</div>
									</div>
									<div className="core-value__items-row--column">
										<div className="shadow-box">
											<div className="description">
												<span>Regional support and deliveries capability based at Malaysia for international clients.</span>
											</div>
											<div className="img-pst">
												<img src={cv05Icon} alt="rocket" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="core-swiper">
								<div className="core-swiper--title">Our Core Value</div>
								<div className="middle">
									<div className="core-swiper--divider"></div>
								</div>
								<AppSwiper props={"core1"}></AppSwiper>
							</div>
						</div>
						<div className="section-7">
							<div className="wrapper">
								<div className="wrapper__where">
									<span className="wrapper__where--slash">/</span>
									<p className="wrapper__where--title">WHERE ARE WE</p>
									<div className="middle">
										<div className="divider"></div>
									</div>
									<span className="wrapper__where--detail">
										We have plans to gradually setting up our operation and sales office in other countries, to better support our clients’ digital transformation initiatives.
									</span>
								</div>
								<div className="wrapper__map">
									<div className="countries">
										<AppMap />
									</div>
									<div className="dropdown">
										<div className="content-flex-center">
											<select onChange={onCountriesSelect}>
												<option value="MY">
													<img src={ySlashIcon} alt="ySlashIcon" /> Malaysia
												</option>
												<option value="SG">Singapore</option>
												<option value="TH">Thailand</option>
												<option value="CM">Cambodia</option>
												<option value="VN">Vietnam</option>
												<option value="KR">Korea</option>
											</select>
										</div>

										<div className="intro">
											{countries.malaysia && (
												<div className="details">
													<span className="title">Malaysia (HQ)</span>
													<div className="divider"></div>
													<p className="info">Established in 2018. Headquarter of atf as IT Service Provider hub based at Malaysia to support International clients’ global IT projects and operations</p>
												</div>
											)}
											{countries.singapore && (
												<div className="details">
													<span className="title">Singapore</span>
													<div className="divider"></div>
													<p className="info">Sales office expansion and setup in SG, the plan is postponed to year 2022 subject to COVID19 situation. Currently has several SG clients</p>
												</div>
											)}
											{countries.thailand && (
												<div className="details">
													<span className="title">Thailand</span>
													<div className="divider"></div>
													<p className="info">In 2024, sales office expansion with local operational support to FINTECH and IR4.0 clients in the country</p>
												</div>
											)}
											{countries.cambodia && (
												<div className="details">
													<span className="title">Cambodia</span>
													<div className="divider"></div>
													<p className="info">In 2024, subject to the ongoing progress with existing client, will expand the office to establish smart technologies with partners</p>
												</div>
											)}
											{countries.vietnam && (
												<div className="details">
													<span className="title">Vietnam</span>
													<div className="divider"></div>
													<p className="info">In 2023, office setup as to address clients’ requirements in taking over various systems handover and support continuity from Vietnam vendors</p>
												</div>
											)}
											{countries.korea && (
												<div className="details">
													<span className="title">Korea</span>
													<div className="divider"></div>
													<p className="info">
														In 2023, IT operation and cloud service provider setup, as collaborative partnership with Korea client after COVID19 situation, being their global IT and digital
														transformation vendor for their business growth
													</p>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
				{services.join && (
					<div className="submitCV">
						<div className="submitCV__title">Submit Your CV</div>
						<div className="submitCV__center">
							<div className="divider"></div>
						</div>
						{/* {!success && (
							<div className="input-fields">
								<div className="input-fields__center">
									<div className={checkName ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Your Name</span>
										</div>
										<div className="input-wrapper">
											<input type="text" name="name" placeholder={input.name.placeholder} value={input.name.value} onChange={onHandleOnChange} />
										</div>
										{checkName && <img src={exclamation} alt="exclamation" />}
									</div>
								</div>
								{checkName && (
									<div className="input-fields__child">
										<span className="error">Please enter your name</span>
									</div>
								)}
								<div className="input-fields__center">
									<div className={checkMobile ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Mobile No.</span>
										</div>
										<div className="input-wrapper">
											<input type="text" name="mobile" placeholder={input.mobile.placeholder} value={input.mobile.value} onChange={onHandleOnChange} />
										</div>
										{checkMobile && <img src={exclamation} alt="exclamation" />}
									</div>
								</div>
								{checkMobile && (
									<div className="input-fields__child">
										<span className="error">Please enter mobile no.</span>
									</div>
								)}
								<div className="input-fields__center">
									<div className={checkEmail ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Email</span>
										</div>
										<div className="input-wrapper">
											<input type="email" name="email" placeholder={input.email.placeholder} value={input.email.value} onChange={onHandleOnChange} />
										</div>
										{checkEmail && <img src={exclamation} alt="exclamation" />}
									</div>
								</div>
								{checkEmail && (
									<div className="input-fields__child">
										<span className="error">Please enter a valid email</span>
									</div>
								)}
								<div className="input-fields__center">
									<div className={checkPosition ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Position</span>
										</div>
										<div className="input-wrapper">
											<select className="custom-select" name="position" onChange={onHandleOnChange}>
												<option value="" disabled selected>
													Please Select
												</option>

												{positionList !== undefined &&
													positionList?.map((item: any[], index: number) => {
														return <option value={positionList[index]?.key}>{positionList[index]?.value.replace("_", " ")}</option>;
													})}
											</select>
										</div>
										{checkPosition && <img src={exclamation} alt="exclamation" />}
									</div>
								</div>
								{checkPosition && (
									<div className="input-fields__child">
										<span className="error">Please select a position</span>
									</div>
								)}
								<div className="input-fields__center">
									<div className={checkUploadCV ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Upload CV</span>
										</div>
										<div className="input-wrapper">
											<input
												type="file"
												name="uploadCV"
												placeholder={input.uploadCV.placeholder}
												accept="image/jpeg, .docx, .pdf, .pptx, image/jpg, image/png"
												className="custom-file-input file-input-button"
												onChange={onHandleFileChange}
											/>
										</div>
									</div>
								</div>
								<div className="input-fields__child">
									<span>Accepted formats: jpeg, docx, pdf, pptx (not more than 4MB) {exceedFileSize && <span className="error">{exceedFileSize}</span>}</span>
								</div>
								{checkUploadCV && (
									<div className="input-fields__child">
										<span className="error">This field is required</span>
									</div>
								)}
								<div className="input-fields__child--right">
									<div className="width70">
										<button onClick={() => onSubmit()} disabled={disableBtn}>
											<div>SUBMIT</div>
											{!disableBtn && (
												<div className="arrowW">
													<img src={circleArrowIcon} alt="white arrow" />
												</div>
											)}
											{!disableBtn && (
												<div className="arrowB">
													<img src={circleArrowBlueIcon} alt="blue arrow" />
												</div>
											)}
											{disableBtn && (
												<div>
													<SpinnerCircularFixed size={28} thickness={200} speed={100} color="#0C4992" secondaryColor="white" />
												</div>
											)}
										</button>
									</div>
								</div>
							</div>
						)} */}
						{!success && (
							<div className="input-fields">
								<div className="input-fields__center">
									<div className={checkName ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Your Name</span>
										</div>
										<div className="input-wrapper-w-mui">
											<FormControl fullWidth sx={{ m: 1, mt: 1 }}>
												<TextField
													name="name"
													placeholder={input.name.placeholder}
													value={input.name.value}
													onChange={onHandleOnChange}
													sx={{ ".css-14dvrb8-MuiOutlinedInput-notchedOutline": { border: "none !important" }, ".css-vcl4be": { border: "none !important" } }}
												></TextField>
											</FormControl>
										</div>
										{checkName && <img src={exclamation} alt="exclamation" />}
									</div>
								</div>
								{checkName && (
									<div className="input-fields__child">
										<span className="error">Please enter your name</span>
									</div>
								)}
								<div className="input-fields__center">
									<div className={checkMobile ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Mobile No.</span>
										</div>
										<div className="input-wrapper-w-mui">
											<FormControl fullWidth sx={{ m: 1, mt: 1 }}>
												<TextField
													name="mobile"
													placeholder={input.mobile.placeholder}
													value={input.mobile.value}
													onChange={onHandleOnChange}
													sx={{ ".css-14dvrb8-MuiOutlinedInput-notchedOutline": { border: "none !important" }, ".css-vcl4be": { border: "none !important" } }}
												></TextField>
											</FormControl>
										</div>
										{checkMobile && <img src={exclamation} alt="exclamation" />}
									</div>
								</div>
								{checkMobile && (
									<div className="input-fields__child">
										<span className="error">Please enter mobile no.</span>
									</div>
								)}
								<div className="input-fields__center">
									<div className={checkEmail ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Email</span>
										</div>
										<div className="input-wrapper-w-mui">
											<FormControl fullWidth sx={{ m: 1, mt: 1 }}>
												<TextField
													name="email"
													placeholder={input.email.placeholder}
													value={input.email.value}
													onChange={onHandleOnChange}
													sx={{ ".css-14dvrb8-MuiOutlinedInput-notchedOutline": { border: "none !important" }, ".css-vcl4be": { border: "none !important" } }}
												></TextField>
											</FormControl>
										</div>
										{checkEmail && <img src={exclamation} alt="exclamation" />}
									</div>
								</div>
								{checkEmail && (
									<div className="input-fields__child">
										<span className="error">Please enter a valid email</span>
									</div>
								)}
								<div className="input-fields__center">
									<div className={checkPosition ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Position</span>
										</div>
										<div className="input-wrapper-w-mui">
											<FormControl sx={{ m: 1, mt: 1, width: "95%" }}>
												<Select
													name="position"
													value={input.position.value}
													onChange={onHandleOnSelect}
													sx={{ ".css-14dvrb8-MuiOutlinedInput-notchedOutline": { border: "none !important" }, ".css-vcl4be": { border: "none !important" } }}
												>
													<MenuItem disabled>Select Position</MenuItem>
													{positionList !== undefined &&
														positionList?.map((item: any[], index: number) => {
															return <MenuItem value={positionList[index]?.key}>{positionList[index]?.value.replace("_", " ")}</MenuItem>;
														})}
												</Select>
											</FormControl>
										</div>
										{/* {checkPosition && <img src={exclamation} alt="exclamation" />} */}
									</div>
								</div>
								{checkPosition && (
									<div className="input-fields__child">
										<span className="error">Please select a position</span>
									</div>
								)}
								<div className="input-fields__center">
									<div className={checkUploadCV ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
										<div className="label">
											<span>Upload CV</span>
										</div>
										<div className="input-wrapper-w-mui">
											<FormControl sx={{ m: 1, mt: 1 }} className="input-wrapper-w-mui__form-text">
												<TextField
													disabled
													name="uploadCV"
													placeholder={input.uploadCV.placeholder}
													value={inputFileName}
													sx={{
														".css-14dvrb8-MuiOutlinedInput-notchedOutline": { border: "none !important" },
														".css-vcl4be": { border: "none !important" },
														".css-1acy1uo-MuiInputBase-input-MuiOutlinedInput-input": {
															padding: "16.5px 0px 16.5px 14px",
															overflow: "hidden",
															whiteSpace: "nowrap",
															textOverflow: "ellipsis",
															width: "98%",
														},
													}}
												></TextField>
											</FormControl>
											<FormControl sx={{ m: 1, mt: 2 }} className="input-wrapper-w-mui__form-btn">
												<label htmlFor="uploadCV">
													<input style={{ display: "none" }} id="uploadCV" name="uploadCV" type="file" onChange={onHandleFileChange} />
													<Button variant="contained" component="span" className="input-wrapper-w-mui__btn-wrapper">
														<span className="input-wrapper-w-mui__btn-text">Upload</span>
														<span>
															<img src={uploadIcon} alt="uploadIcon" />
														</span>
													</Button>
												</label>
											</FormControl>
										</div>
									</div>
								</div>
								<div className="input-fields__child">
									<span>Accepted formats: jpeg, docx, pdf, pptx (not more than 4MB) {exceedFileSize && <span className="error">{exceedFileSize}</span>}</span>
								</div>
								{checkUploadCV && (
									<div className="input-fields__child">
										<span className="error">This field is required</span>
									</div>
								)}
								<div className="input-fields__child--right">
									<div className="width70">
										<button onClick={() => onSubmit()} disabled={disableBtn}>
											<div>SUBMIT</div>
											{!disableBtn && (
												<div className="arrowW">
													<img src={circleArrowIcon} alt="white arrow" />
												</div>
											)}
											{!disableBtn && (
												<div className="arrowB">
													<img src={circleArrowBlueIcon} alt="blue arrow" />
												</div>
											)}
											{disableBtn && (
												<div>
													<SpinnerCircularFixed size={28} thickness={200} speed={100} color="#0C4992" secondaryColor="white" />
												</div>
											)}
										</button>
									</div>
								</div>
							</div>
						)}
						{success && (
							<div className="submit-success">
								<div className="center">
									<img src={sentIcon} alt="sentIcon" />
								</div>
								<div className="center">
									<p>All Good! We will get in touch soon!</p>
								</div>
							</div>
						)}
					</div>
				)}
				<AppFooter />
			</div>
		</div>
	);
};

export default PageWhoWeAre;
