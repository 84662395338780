// library
import { useRef } from "react";

// dto
import { ImperativeHandleAppSubscribeCardDto } from "dto/components/app-subscribe-card-dto";

// components
import AppSubscribeCard from "components/app-subscribe-card";

// assets
import blurSkylinePic from "assets/images/blurSkylinePic.png";

const AppNewsCard = () => {
	const subscribeRef = useRef<ImperativeHandleAppSubscribeCardDto>(null);
	const newsTitle = "Discover more about our news";
	const newsDescription = "Lorem Ipsum is simply dummy text of the printing and typesetting industry";

	const onHandleShow = () => {
		subscribeRef.current?.onHandleShow();
	};

	return (
		<div className="app-news-card">
			<div className="news-card">
				<div className="news-card__image" style={{ backgroundImage: `url(${blurSkylinePic})` }}>
					<div className="news-card__container">
						<div className="news-card__wrapper">
							<p className="news-card__title">{newsTitle}</p>

							<p className="news-card__description">{newsDescription}</p>
						</div>

						<button className="subscribe-button" onClick={onHandleShow}>
							<p className="subscribe-button__text">Subscribe now</p>
						</button>
					</div>
				</div>

				<AppSubscribeCard ref={subscribeRef} />
			</div>
		</div>
	);
};
export default AppNewsCard;
