// library
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// common
import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";

//dto
import AllArticleDto from "dto/pages/page-all-articles-dto";
import ErrorResponseDto from "dto/services/error-response-dto";

// components
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppPagination from "components/app-pagination";
import AppArticleCard from "components/app-article-card";

// assets
import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";

const PageAllArticles = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const [categories, setCategories] = useState<string[]>([]);
	const [selectedButton, setSelectedButton] = useState("All");
	const [allArticles, setAllArticles] = useState<AllArticleDto[]>([]);
	const [similarArticles, setSimilarArticles] = useState<AllArticleDto[]>([]);

	const queryParams = useRef({ search: "", page: 0, size: 9 });

	const newsImage = resolutionImg;

	//prettier-ignore
	const buttonClassnames = useMemo(() => (option: string) => classNames({
		"custom-button": true,
		"custom-button--selected": selectedButton === option
	}), [selectedButton]);

	const articlesToDisplay = useMemo(() => {
		if (selectedButton === "All") return allArticles;

		return similarArticles;
	}, [selectedButton, allArticles, similarArticles]);

	const totalPageNumber = useMemo(() => Math.ceil(articlesToDisplay.length / 9), [articlesToDisplay]);

	const paginatedArticles = useMemo(() => articlesToDisplay.slice(currentPage * 9, (currentPage + 1) * 9), [articlesToDisplay, currentPage]);

	const onHandleNext = useCallback(() => {
		if (currentPage !== totalPageNumber - 1) {
			setCurrentPage((prev) => ++prev);
		}
	}, [currentPage, totalPageNumber]);

	const onHandleBack = useCallback(() => {
		if (currentPage !== 0) {
			setCurrentPage((prev) => --prev);
		}
	}, [currentPage]);

	const onHandleGetCategoriesTitle = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 5, page: params.page };

			response = await api.get.categoryTitle.categoryTitle(payload);

			const categoryTitles = response.data.data.list.content.map((category: any) => category.title);

			const uniqueCategories = [...new Set(["All", ...categoryTitles])];

			setCategories(uniqueCategories);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetAllArticles = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 100, page: params.page, param: params.search };

			response = await api.get.ourArticles.ourArticles(payload);

			setAllArticles(response.data.data.list.content);
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	const onHandleGetSimilarArticles = useCallback(async (category: string) => {
		let response = null;

		try {
			response = await api.get.similarArticle.article(category);

			setSimilarArticles(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	//prettier-ignore
	const onHandleSelect = useCallback((option: string) => {
		setSelectedButton(option);
		setCurrentPage(0);
	
		if (option === "All") {
			onHandleGetAllArticles();
		} else {
			onHandleGetSimilarArticles(option);
		} 
	}, [onHandleGetAllArticles, onHandleGetSimilarArticles]);

	useEffect(() => {
		onHandleGetCategoriesTitle();
		onHandleGetAllArticles();
	}, [onHandleGetAllArticles, onHandleGetCategoriesTitle]);

	return (
		<div className="page-all-articles">
			<div className="articles">
				<AppNavbar />

				<HeaderCard backgroundImage={newsImage} title={"Learning centre"} />

				<div className="button-section">
					{categories.map((option) => (
						<button key={option} className={buttonClassnames(option)} onClick={() => onHandleSelect(option)}>
							{option}
						</button>
					))}
				</div>

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">Latest Articles</p>

						{totalPageNumber > 1 && <AppPagination totalPageNumber={totalPageNumber} currentPage={currentPage} onHandleNext={onHandleNext} onHandleBack={onHandleBack} />}
					</div>

					<div className="articles-section__wrapper">
						{paginatedArticles.map((o) => {
							const articleLink = `${pathnames.PageArticle}/${o.id}`;

							return <AppArticleCard title={o.title} description={o.category} image={o.thumbnail} key={o.id} link={articleLink} />;
						})}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageAllArticles;
