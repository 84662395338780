import { memo, useMemo } from "react";

import classNames from "common/class-names";

import AppButtonDto from "dto/components/app-button-dto";

const AppButton = (p: AppButtonDto) => {
	const { label, icon, className, outline, text, small, ...props } = p;

	const buttonClassNames = useMemo(() => {
		return classNames({
			"app-button": true,
			"app-button--small": Boolean(small),
			"app-button--text": Boolean(text),
			"app-button--outline": Boolean(outline),
			...(className && { [className]: true }),
		});
	}, [className, small, text, outline]);

	return (
		<button className={buttonClassNames} {...props}>
			<div className="button">
				<p className="button__label">{label}</p>

				{icon}
			</div>
		</button>
	);
};

export default memo(AppButton);
