import cardBox from "assets/images/card-box.svg";
import { AppServiceSolutionCardDto } from "dto/components/app-services-solution-card-dto";

const AppServiceSolutionCard = ({ title, description }: AppServiceSolutionCardDto) => {
	return (
		<div className="app-service-solution-card">
			<div className="service-card">
				<div className="service-card__image" style={{ backgroundImage: `url(${cardBox})` }}>
					<div className="service-card__wrapper">
						<p className="service-card__title">{title}</p>

						<p className="service-card__description">{description}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppServiceSolutionCard;
