import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import pathnames from "routes/pathnames";
import AppFooter from "../components/app-footer";
import AppDrawer from "components/pages/menu/app-drawer";
import { SpinnerCircularFixed } from "spinners-react";

import logo from "assets/images/atf-logo.png";
import circleArrowIcon from "assets/images/circle-arrow-white.png";
import circleArrowBlueIcon from "assets/images/circle-arrow-blue.png";
import locationImg from "assets/images/pages/page-chat-with-us/location.png";
import emailImg from "assets/images/pages/page-chat-with-us/email.png";
import sentIcon from "assets/images/pages/page-chat-with-us/ico-Sent.png";
import exclamation from "assets/images/pages/page-chat-with-us/exclamation.png";

type MapLocation = {
	label: string;
	address: string;
	url: string;
	value: string;
};

const PageChatWithUS = () => {
	const [chat, setChat] = useState({ talk: true, location: false });
	const [success] = useState(false);

	const onSelect = (s: string) => {
		if (s === "talk") {
			setChat({ talk: true, location: false });
		} else {
			setChat({ talk: false, location: true });
		}
	};

	const inputField = {
		name: {
			value: "",
			error: false,
			placeholder: "",
			regex: /^[a-zA-Z0-9 ./-]*$/,
		},
		email: {
			value: "",
			error: false,
			placeholder: "",
			regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		},
		message: {
			value: "",
			error: false,
			placeholder: "",
		},
	};

	const [input, setInput] = useState(inputField);
	const [disableBtn] = useState(false);

	const onHandleOnChange = (event: any) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;
		setInput({
			...input,
			[name]: {
				value: value,
			},
		});
	};

	const onHandleOnChangeMenu = (event: any) => {
		const value = event.currentTarget.value;
		if (value === "chat") {
			setChat({ talk: true, location: false });
		} else {
			setChat({ talk: false, location: true });
		}
	};

	const [checkName, setCheckName] = useState(false);
	const [checkEmail, setCheckEmail] = useState(false);
	const [checkMessage, setCheckMessage] = useState(false);

	const checkValidation = () => {
		let nameCheck = inputField.name.regex.test(input.name.value) === true;
		let emailCheck = inputField.email.regex.test(input.email.value) === true;

		if ((input.name.value.trim() !== "" && nameCheck) === false) {
			setCheckName(true);
		} else {
			setCheckName(false);
		}
		if ((input.email.value.trim() !== "" && emailCheck) === false) {
			setCheckEmail(true);
		} else {
			setCheckEmail(false);
		}
		if ((input.message.value !== "") === false) {
			setCheckMessage(true);
		} else {
			setCheckMessage(false);
		}
	};

	const onSubmit = () => {
		checkValidation();
		// let nameCheck = inputField.name.regex.test(input.name.value) === true;
		// let emailCheck = inputField.email.regex.test(input.email.value) === true;
		// if (input.name.value.trim() === "" || input.email.value.trim() === "" || input.message.value.trim() === "" || nameCheck === false || emailCheck === false) {
		// 	return;
		// } else {
		// 	setDisableBtn(true);
		// 	setTimeout(() => {
		// 		setDisableBtn(false);
		// 	}, 6000);
		// }
		// let generalEnquiryRequest = {
		// 	name: input.name.value,
		// 	email: input.email.value,
		// 	message: input.message.value,
		// };
		// fetch(process.env.REACT_APP_API_URL + API.ENDPOINT_PATH.POST_GENERAL, {
		// 	method: "POST",
		// 	headers: {
		// 		Accept: "*/*",
		// 		"Content-Type": "application/json",
		// 	},
		// 	body: JSON.stringify(generalEnquiryRequest),
		// })
		// 	.then(function (response) {
		// 		return response.json();
		// 	})
		// 	.then(function (data) {
		// 		const response = data;
		// 		if (response.responseCode === 200) {
		// 			setSuccess(true);
		// 		} else {
		// 			setSuccess(false);
		// 		}
		// 	});
	};

	const mapLocation: Array<MapLocation> = useMemo(
		() => [
			{
				label: "Malaysia",
				address: "Tower 2, Puchong Financial Corporate Centre, Unit 8F-1C, Jalan Puteri 1/2, Bandar Puteri Puchong, 47100 Puchong, Selangor",
				url: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15937.019379344374!2d101.6152365!3d3.0261095!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdb357de46ef29%3A0x264fdf08a7b10c2d!2sPuchong%20Financial%20Corporate%20Center%2C%200101!5e0!3m2!1sen!2smy!4v1696069650379!5m2!1sen!2smy",
				value: "malaysia",
			},
			{
				label: "Singapore",
				address: "Level 49 & 50 One Raffles Quay, Singapore 048583",
				url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1937.6335463936866!2d103.8517145214697!3d1.2810753291058328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190e670477c9%3A0x2ed9f80272ad7f47!2sThe%20Executive%20Centre%20-%20One%20Raffles%20Quay%20%7C%20Serviced%20%26%20Virtual%20Offices%20and%20Workspace!5e0!3m2!1sen!2smy!4v1666842319786!5m2!1sen!2smy",
				value: "singapore",
			},
			{
				label: "Thailand",
				address: "Room No. 1814, 18th Floor, Sathorn Thani Tower II, 92/53 North Sathon Road, Silom, Bang Rak, Bangkok 10500",
				url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.925757250463!2d100.5307541!3d13.7229446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f7b0d90db6b%3A0xd35eb597270aa98b!2sEBC%20-%20Executive%20Business%20Center!5e0!3m2!1sen!2smy!4v1694685720384!5m2!1sen!2smy",
				value: "thailand",
			},
		],
		[]
	);

	const [location, setLocation] = useState<string>("malaysia");
	const selectedLocation = useMemo(() => mapLocation.find((o) => o.value === location), [mapLocation, location]);

	const selectLocation = (event: React.MouseEvent<HTMLButtonElement>) => {
		const value = event.currentTarget.value;
		setLocation(value);
	};

	const onSelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		setLocation(value);
	};

	return (
		<div className="page-chat-with-us">
			<div className="chat-w-us">
				<div className="section-1">
					<div className="top-menu">
						<div className="img-size">
							<Link to={pathnames.PageHome}>
								<img src={logo} alt="logo" />
							</Link>
							<AppDrawer />
						</div>
					</div>
					<div className="section-1__main-img">
						<div className="center">
							<div className="content-container">
								<div className="content__title">
									<span className="content__title--highlight">We’re Ready, Let’s Talk</span>
								</div>
								<div className="content__description">
									<p>We’d love to hear from you. Here’s how you can reach us</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-2">
					<div className="why-atf">
						<div className="content">
							<span className={chat.talk ? "content__description active" : "content__description"} onClick={() => onSelect("talk")}>
								<span className="hoverT">/</span> Chat with Us
							</span>
							<span className={chat.location ? "content__description active" : "content__description"} onClick={() => onSelect("location")}>
								<span className="hoverT">/</span> Location
							</span>
						</div>
						<div className="dropdown">
							<div className="dropdown__wrapper">
								<span className="slash">/</span>
								<select className="select-frame" onChange={onHandleOnChangeMenu}>
									<option value="chat">Chat with Us</option>
									<option value="location">Location</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="section-3">
					{chat.talk && (
						<div className="section-2__container1">
							{!success && (
								<div className="input-fields">
									<div className="input-fields__center">
										<div className={checkName ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
											<div className="label">
												<span>Your Name</span>
											</div>
											<div className="input-wrapper">
												<input type="text" name="name" placeholder={input.name.placeholder} value={input.name.value} onChange={onHandleOnChange} />
											</div>
											{checkName && <img src={exclamation} alt="exclamation" />}
										</div>
									</div>
									{checkName && (
										<div className="input-fields__child">
											<span className="error">Please enter your name</span>
										</div>
									)}
									<div className="input-fields__center">
										<div className={checkEmail ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
											<div className="label">
												<span>Email</span>
											</div>
											<div className="input-wrapper">
												<input type="email" name="email" placeholder={input.email.placeholder} value={input.email.value} onChange={onHandleOnChange} />
											</div>
											{checkEmail && <img src={exclamation} alt="exclamation" />}
										</div>
									</div>
									{checkEmail && (
										<div className="input-fields__child">
											<span className="error">Please enter a valid email</span>
										</div>
									)}
									<div className="input-fields__center">
										<div className={checkMessage ? "input-fields__grey-border input-fields__grey-border--error" : "input-fields__grey-border"}>
											<div className="label--message">
												<span>Message</span>
											</div>
											<div className="input-wrapper">
												<textarea name="message" value={input.message.value} onChange={onHandleOnChange}></textarea>
											</div>
											{checkMessage && (
												<div className="exclaimation-height">
													<img src={exclamation} alt="exclamation" />
												</div>
											)}
										</div>
									</div>
									{checkMessage && (
										<div className="input-fields__child">
											<span className="error">This field is required</span>
										</div>
									)}
									<div className="input-fields__child--right">
										<div className="width70">
											<button onClick={() => onSubmit()} disabled={disableBtn}>
												<div>SUBMIT</div>
												{!disableBtn && (
													<div className="arrowW">
														<img src={circleArrowIcon} alt="white arrow" />
													</div>
												)}
												{!disableBtn && (
													<div className="arrowB">
														<img src={circleArrowBlueIcon} alt="blue arrow" />
													</div>
												)}
												{disableBtn && (
													<div>
														<SpinnerCircularFixed size={28} thickness={200} speed={100} color="#0C4992" secondaryColor="white" />
													</div>
												)}
											</button>
										</div>
									</div>
								</div>
							)}
							{success && (
								<div className="submit-success">
									<div className="center">
										<img src={sentIcon} alt="sentIcon" />
									</div>
									<div className="center">
										<p>All Good! We will get in touch soon!</p>
									</div>
								</div>
							)}
						</div>
					)}
					{chat.location && (
						<div className="section-2__container2">
							<div className="select-country">
								<div className="btn-wrapper">
									{mapLocation.map((o) => {
										return (
											<button key={o.value} className={location === o.value ? "btn active" : "btn"} value={o.value} onClick={selectLocation}>
												{o.label}
											</button>
										);
									})}
								</div>
							</div>
							<div className="select-country">
								<div className="dropdown">
									<select onChange={onSelectOnChange}>
										{mapLocation.map((o) => {
											return (
												<option key={o.value} value={o.value}>
													{o.label}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="map">
								<div className="gMap">
									{selectedLocation && <iframe title="Malaysia" src={selectedLocation.url} width="600" height="450" allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />}
								</div>
							</div>
							<div className="info">
								<div className="info-container">
									<div className="info-wrapper1">
										<div className="content">
											<img src={locationImg} alt="locationImg" className="mright" />
										</div>
										<div className="content">{selectedLocation && <span>{selectedLocation.address}</span>}</div>
									</div>
									<div className="info-wrapper">
										<div className="info-wrapper__content">
											<img src={emailImg} alt="emailImg" className="mright" />
											<span>admin@altecflex.com</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<AppFooter />
			</div>
		</div>
	);
};

export default PageChatWithUS;
