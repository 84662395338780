//library
import { useMemo } from "react";
import { Link } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// dto
import AppNavbarDto from "dto/components/app-navbar-dto";

// asset
import logo from "assets/images/atf-logo.png";

const AppNavbar = ({ shouldHeaderFixed = false }: AppNavbarDto) => {
	//prettier-ignore
	const menuItems = useMemo(() => [
		{ title: "Services", pathName: pathnames.PageAllProducts },
		{ title: "Join The Team", pathName: pathnames.PageCareer },
		{ title: "Learning Centre", pathName: pathnames.PageAllArticles },
		{ title: "Contact Us", pathName: pathnames.PageConnectWithUs },
	], []);

	return (
		<div className={`app-navbar${shouldHeaderFixed ? " fixed-header" : ""}`}>
			<div className="navbar">
				<div className="navbar__wrapper">
					<div className="navbar__container">
						<Link to={pathnames.PageHome}>
							<img src={logo} alt="logo" />
						</Link>

						<div className="nav-menu">
							{menuItems.map((o, i) => {
								return (
									<Link className="nav-menu__item" to={o.pathName} key={i}>
										<p className="nav-menu__label">{o.title}</p>
									</Link>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppNavbar;
