import { memo, useCallback, useMemo } from "react";

import classNames from "common/class-names";

import AppCheckboxDto from "dto/components/app-checkbox-dto";

import CheckboxIcon from "assets/images/components/app-checkbox/checker-icon.svg";

const AppCheckbox = (p: AppCheckboxDto) => {
	const value = useMemo(() => p.value, [p.value]);
	const isInvalid = useMemo(() => Boolean(p.error) && Boolean(p.touched), [p.error, p.touched]);

	const checkboxClassNames = useMemo(
		() =>
			classNames({
				"app-checkbox": true,
				"app-checkbox--checked": value !== undefined && Boolean(value),
				"app-checkbox--error": Boolean(p.error),
			}),
		[value, p.error]
	);

	const handleClick = useCallback(() => {
		p.onChange(p.name, !value);
	}, [value, p]);

	return (
		<div className={checkboxClassNames}>
			<div className="checkbox">
				<div className="checkbox__wrapper">
					<button type="button" className="checkbox__button" disabled={p.disabled} onClick={handleClick} data-checked={value}>
						<img src={CheckboxIcon} alt="checkbox" />{" "}
					</button>

					{p.children}

					{p.required && <span className="checkbox__required"> *</span>}
				</div>

				{isInvalid && (
					<div className="checkbox__footer">
						<p className="checkbox__error">{p.error}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(AppCheckbox);
