// library
import { useCallback, useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// services
import api from "services/api";
import getAboutUsListing from "services/get-about-us-listing";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import PageConnectWithUsDto from "dto/pages/page-connect-with-us-dto";
import ErrorResponseDto from "dto/services/error-response-dto";
import { ImperativeHandleAppPopupCardDto } from "dto/components/app-popup-card-dto";

//components
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppFooter from "components/app-footer";
import AppNavbar from "components/app-navbar";
import AppTextarea from "components/app-textarea";
import AppCheckbox from "components/app-checkbox";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppPopupCard from "components/app-popup-card";
import AppSelectInput from "components/app-select-input";
import AppOfficeCard from "components/pages/page-connect-with-us/app-office-card";

// assets
import squadImg from "assets/images/pages/page-home/thumb-delivery-squad.jpg";
import emailReceived from "assets/images/email-received.png";

const PageConnectWithUs = () => {
	const modalRef = useRef<ImperativeHandleAppPopupCardDto>(null);
	const [officeBranches, setOfficeBranches] = useState<any[]>([]);

	const newsImage = squadImg;
	const screenTitle = "Let's Connect";
	//prettier-ignore
	const screenDescription = "We're excited to connect with you and learn more about your real estate goals. Use the form below to get in touch with Estatein. Whether you're a prospective client, partner, or simply curious about our services, we're here to answer your questions and provide the assistance you need.";
	const screenTitle2 = "Discover Our Office Locations";
	//prettier-ignore
	const screenDescription2 = "Estatein is here to serve you across multiple locations. Whether you're looking to meet our team, discuss real estate opportunities, or simply drop by for a chat, we have offices conveniently located to serve your needs. Explore the categories below to find the Estatein office nearest to you";
	const title = "All Good! We will get in touch soon!";
	const image = emailReceived;

	const validationSchema = yup.object({
		companyName: yup.string().required("This field is required"),
		email: yup.string().email("Please enter a valid email address").required("This field is required"),
		phone: yup
			.string()
			.matches(/^[0-9]+$/, "Please enter a valid phone number")
			.min(10, "Please enter a valid phone number")
			.required("This field is required"),
		referralSourceId: yup.string().required("This field is required"),
		message: yup.string().required("This field is required"),
		terms: yup
			.boolean()
			.oneOf([true], "You must accept the Terms of Use and Privacy Policy to proceed")
			.required("This field is required")
			.test("isTouched", "You must accept the Terms of Use and Privacy Policy to proceed", function (value) {
				return this.parent.terms !== undefined;
			}),
	});

	//prettier-ignore
	const formik = useFormik<PageConnectWithUsDto>({
		initialValues: { companyName: "", email: "", phone: "", referralSourceId: "", message: "", terms: false },
		validationSchema,
		onSubmit: async (values) => {
			try {
				await api.post.contactUs(values);

				modalRef.current?.onHandleShow();
			} catch (error) {
				const err = error as Error | ErrorResponseDto;

				serveRequestErrors(err);
			}
		},
	});

	const fetchOfficeBranches = useCallback(async () => {
		try {
			const response = await api.get.branches("active", { page: 0, size: 99 });

			setOfficeBranches(response.data.data.list.content);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	//prettier-ignore
	const handleTnc = useCallback((name: string, value: boolean) => {
		formik.setFieldValue(name, value);
	}, [formik]);

	useEffect(() => {
		fetchOfficeBranches();
	}, [fetchOfficeBranches]);

	return (
		<div className="page-connect-with-us">
			<div className="connect-with-us">
				<AppNavbar />

				<HeaderCard backgroundImage={newsImage} title={"Connect With Us"} />

				<div className="services-section">
					<p className="services-section__title">{screenTitle}</p>

					<div className="services-section__header">
						{/* prettier-ignore */}
						<p className="connect-with-us__description">{screenDescription}</p>
					</div>
				</div>

				<form className="connect-with-us__form" onSubmit={formik.handleSubmit}>
					<section className="connect-with-us__form-container">
						{/*prettier-ignore*/}
						<AppInput required type="text" name="companyName" label="Company Name" placeholder="Enter your company name" value={formik.values.companyName} touched={formik.touched.companyName} error={formik.errors.companyName} onChange={formik.handleChange}/>

						{/*prettier-ignore*/}
						<AppInput required name="email" placeholder="Enter your email" type="email" label="Email" value={formik.values.email} touched={formik.touched.email} error={formik.errors.email} onChange={formik.handleChange}/>

						{/*prettier-ignore*/}
						<AppInput required type="tel" name="phone" label="Phone" placeholder="Enter your phone number" value={formik.values.phone} touched={formik.touched.phone} error={formik.errors.phone} onChange={formik.handleChange}/>

						<AppSelectInput
							required
							name="referralSourceId"
							label="How Did You Hear About Us?"
							placeholder="Select"
							value={formik.values.referralSourceId}
							touched={formik.touched.referralSourceId}
							error={formik.errors.referralSourceId}
							loadOptions={getAboutUsListing}
							onChange={formik.handleChange}
						/>

						{/*prettier-ignore*/}
						<AppTextarea required id="message" name="message" label="Message" placeholder="Enter your Message here..." value={formik.values.message} touched={formik.touched.message} error={formik.errors.message} onChange={formik.handleChange}/>
					</section>

					<div className="submit-container">
						<AppCheckbox required name="terms" value={formik.values.terms} touched={formik.touched.terms} error={formik.errors.terms} onChange={handleTnc}>
							<p className="submit-container__text" id="agreeTnc">
								I agree with <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>
							</p>
						</AppCheckbox>

						<AppButton type="submit" id="submit" label="Send Your Message" className="button-section button" disabled={formik.isSubmitting} />
					</div>
				</form>

				<div className="services-section">
					<p className="services-section__title">{screenTitle2}</p>

					<div className="services-section__header">
						{/* prettier-ignore */}
						<p className="connect-with-us__description">{screenDescription2}</p>
					</div>
				</div>

				<div className="services-section">
					<div className="services-section__wrapper">
						{officeBranches.map((branch) =>
							//prettier-ignore
							<AppOfficeCard key={branch.id} title={branch.headquarter ? "Main Headquarters" : "Regional Office"} location={branch.fullAddress.country} address={`${branch.fullAddress.streetAddress}, ${branch.fullAddress.city}, ${branch.fullAddress.state}, ${branch.fullAddress.country} ${branch.fullAddress.postcode}`} email={branch.contactEmail} googleMapLink={branch.location}/>
						)}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />

				<AppPopupCard ref={modalRef} image={image} title={title} />
			</div>
		</div>
	);
};

export default PageConnectWithUs;
