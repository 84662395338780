import { SlideImageDto } from "dto/components/app-slideshow-dto";
import { useEffect } from "react";

const AppSlideShow = ({ slideImages }: SlideImageDto) => {
	useEffect(() => {
		const root = document.documentElement;
		const style = getComputedStyle(root);
		const marqueeContent = document.querySelector("ul.marquee-content");
		const marqueeElementsDisplayed = parseInt(style.getPropertyValue("--marquee-elements-displayed"), 10);

		if (marqueeContent && marqueeContent.children.length > 0) {
			root.style.setProperty("--marquee-elements", marqueeContent.children.length.toString());

			for (let i = 0; i < marqueeElementsDisplayed; i++) {
				marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
			}
		}
	}, [slideImages]);

	return (
		<div className="app-slideshow">
			<div className="slide-container">
				<div className="marquee-wrapper">
					<div className="marquee">
						<ul className="marquee-content">
							{slideImages.map((image, index) => (
								<li key={index} className="item">
									<img className="img" src={image.url} alt="" />
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppSlideShow;
