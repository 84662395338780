const pathnames = {
	PageHome: "/",
	PageWhoWeAre: "/who-we-are",
	PageWhoWeAreJoin: "/who-we-are/:link",
	PageService: "/service",
	PageChatWithUs: "/chat-with-us",
	PageProduct: "/product",
	PageLearning: "/learning",
	PageArticle: "/article",
	PageAllArticles: "/all-articles",
	PageAllProducts: "/all-products",
	PageProjectShowCase: "/project-show-case",
	PageCareer: "/career",
	PageConnectWithUs: "/connect-with-us",
};

export default pathnames;
