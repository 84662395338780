import { AppHeaderCardDto } from "dto/components/app-header-card-dto";

const HeaderCard = ({ backgroundImage, title, customClass }: AppHeaderCardDto) => {
	return (
		<div className="app-header-card">
			<div className="app-header-card__image" style={{ backgroundImage: `url(${backgroundImage})` }}>
				<p className={`app-header-card__title ${customClass}`}>{title}</p>
			</div>
		</div>
	);
};

export default HeaderCard;
