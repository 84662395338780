import services from "services";

import encodeQueryParams from "common/encode-query-params";

import END_POINT_PATH from "constants/end-point-path";

import PageConnectWithUsDto from "dto/pages/page-connect-with-us-dto";

const api = {
	get: {
		solutions: {
			solutions: (params: { param: string; size: number; page: number }) => services.get(END_POINT_PATH.SOLUTIONS.SOLUTIONS + encodeQueryParams(params)),
		},
		faq: {
			faq: () => services.get(END_POINT_PATH.FAQ.FAQ),
		},
		ourArticles: {
			ourArticles: (params: { param: string; size: number; page: number }) => services.get(END_POINT_PATH.ARTICLE.OUR_ARTICLES + encodeQueryParams(params)),
		},
		clients: {
			client: (id: string) => services.get(END_POINT_PATH.CLIENTS.CLIENT + id),
			clients: () => services.get(END_POINT_PATH.CLIENTS.CLIENTS),
		},
		partner: {
			partner: () => services.get(END_POINT_PATH.PARTNER.PARTNER),
		},
		articleDetails: {
			article: (id: string) => services.get(END_POINT_PATH.ARTICLE.ARTICLE_DETAILS + id),
		},
		similarArticle: {
			article: (category: string) => services.get(END_POINT_PATH.ARTICLE.SIMILAR_ARTICLE + `${category}`),
		},
		services: {
			services: (main: string, params: { size: number; page: number }) => services.get(END_POINT_PATH.SERVICES.SERVICES + `${main}` + encodeQueryParams(params)),
		},
		categoryTitle: {
			categoryTitle: (params: { size: number; page: number }) => services.get(END_POINT_PATH.ARTICLE.CATEGORY_TITLE + encodeQueryParams(params)),
		},
		dropdown: {
			retrieveCategory: (category: string, params: { page: number; size: number }) => services.get(END_POINT_PATH.DROPDOWN.RETRIEVE_CATEGORY + `${category}` + encodeQueryParams(params)),
		},
		branches: (status: string, params: { page: number; size: number }) => services.get(`${END_POINT_PATH.BRANCH.BRANCH}/${status}` + encodeQueryParams(params)),
		career: {
			career: (params: { param: string; size: number; page: number }) => services.get(END_POINT_PATH.CAREER.CAREER + encodeQueryParams(params)),
			resource: () => services.get(END_POINT_PATH.CAREER.RESOURCE),
		},
	},
	post: {
		contactUs: (formData: PageConnectWithUsDto) => services.post("/enquiry/save", formData),
		career: {
			form: (formData: FormData) => services.post(END_POINT_PATH.CAREER.FORM, formData, { headers: { "Content-Type": "multipart/form-data" } }),
		},
	},
	patch: {},
	put: {},
};

export default api;
