import { AppCompanyCardDto } from "dto/components/app-company-card-dto";

const AppCompanyCard = ({ description, number, workforce, description1 }: AppCompanyCardDto) => {
	return (
		<div className="app-company-card">
			<div className="company-card">
				<p className="company-card__description">{description}</p>

				<div className="company-card__section">
					<p className="company-card__number">{number}</p>

					<p className="company-card__title">{workforce}</p>

					<p className="company-card__description">{description1}</p>
				</div>
			</div>
		</div>
	);
};

export default AppCompanyCard;
