// library
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// services
import api from "services/api";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { CareerDto, JoinTheTeamValue, ResourceDataDto } from "dto/pages/page-career-dto";
import { ImperativeHandleAppPopupCardDto } from "dto/components/app-popup-card-dto";

// components
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppFooter from "components/app-footer";
import AppNavbar from "components/app-navbar";
import AppTextarea from "components/app-textarea";
import AppCheckbox from "components/app-checkbox";
import AppSlideShow from "components/app-slideshow";
import HeaderCard from "components/app-header-card";
import AppPopupCard from "components/app-popup-card";
import AppPagination from "components/app-pagination";
import AppSelectInput from "components/app-select-input";
import AppPositionCard from "components/app-position-card";
import AppWorkHereCard from "components/app-work-here-card";
import AppFileUploadField from "components/app-file-upload-field";

// assets
import laptopImg from "assets/images/laptop.svg";
import emailReceived from "assets/images/email-received.png";
import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";

const PageCareer = () => {
	const formRef = useRef<HTMLDivElement | null>(null);
	const modalRef = useRef<ImperativeHandleAppPopupCardDto>(null);
	const queryParams = useRef({ search: "", page: 0, size: 9 });
	const [currentPage, setCurrentPage] = useState(0);
	const [selectedVacancy, setSelectedVacancy] = useState("");
	const [selectedJobType, setSelectedJobType] = useState("");
	const [vacancyData, setVacancyData] = useState<CareerDto[]>([]);
	const [resourceData, setResourceData] = useState<ResourceDataDto[]>([]);
	const joinTheTeamContent = useMemo(() => resourceData.find((content) => content.page === "page.joinTheTeam")?.values?.find((value) => value.key === "page.joinTheTeam")?.value, [resourceData]);
	const joinTheTeamMedia = useMemo(() => {
		const mediaValue = resourceData.find((content) => content.page === "page.joinTheTeam")?.values?.find((value) => value.key === "page.joinTheTeam.media")?.value;

		if (Array.isArray(mediaValue)) {
			return mediaValue.map((url) => ({
				url: url as string,
			}));
		}

		return [];
	}, [resourceData]);

	//prettier-ignore
	const selectOptions = useMemo(() => Array.from(new Set(vacancyData.map((vacancy) => vacancy.title))).map((title) => ({ label: title, value: title })), [vacancyData]);

	const totalPageNumber = useMemo(() => Math.ceil(vacancyData.length / 6), [vacancyData.length]);

	//prettier-ignore
	const currentJobData = useMemo(() => vacancyData.filter((vacancy) => (!selectedVacancy || vacancy.title === selectedVacancy) && (!selectedJobType || vacancy.employmentType === selectedJobType)).slice((currentPage + 1) * 6 - 6, (currentPage + 1) * 6), [currentPage, selectedVacancy, selectedJobType, vacancyData]);

	//prettier-ignore
	const onSelectJobTypeOptions = useMemo(() => [
        { label: "All Job Types", value: "" },
        ...Array.from(new Set(vacancyData.map((vacancy) => vacancy.employmentType))).map((employmentType) => ({
            label: employmentType,
            value: employmentType,
        })),
    ], [vacancyData]);

	//prettier-ignore
	const onSelectVacancyOptions = useMemo(() => [
		{ label: "All Vacancies", value: "" },
		...Array.from(new Set(vacancyData.map((vacancy) => vacancy.title))).map((title) => ({
			label: title,
			value: title,
		})),
	], [vacancyData]);

	const title = "Your application has been successfully submitted!";

	const validationSchema = yup.object({
		name: yup.string().required("This field is required"),
		email: yup.string().email("Please enter a valid email address").required("This field is required"),
		phone: yup
			.string()
			.matches(/^[0-9]+$/, "Please enter a valid phone number")
			.min(10, "Please enter a valid phone number")
			.required("This field is required"),
		roleType: yup.string().required("This field is required"),
		message: yup.string().required("This field is required"),
		terms: yup.boolean().oneOf([true], "You must accept the Terms of Use and Privacy Policy to proceed").required("This field is required"),
		file: yup
			.mixed()
			.test("fileSize", "Uploaded file cannot exceed 4 MB", (value) => !value || (value && value.size <= 4000000))
			.test(
				"fileFormat",
				"Unsupported Format",
				(value) =>
					!value ||
					(value &&
						["image/jpeg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.presentationml.presentation"].includes(
							value.type
						))
			),
	});

	//prettier-ignore
	const formik = useFormik({
		initialValues: { name: "", email: "", phone: "", roleType: "", message: "", terms: false, file: null },
		validationSchema,
		onSubmit: async (values) => {
			const formData = new FormData();

			formData.append("name", values.name);
			formData.append("email", values.email);
			formData.append("phone", values.phone);
			formData.append("jobType", values.roleType);
			formData.append("message", values.message);

			if (values.file) {
				formData.append("resume", values.file);
			}

			try {
				await api.post.career.form(formData);

				modalRef.current?.onHandleShow();
			} catch (unknown: unknown) {
				const error = unknown as Error | ErrorResponseDto;

				serveRequestErrors(error);
			}
		},
	});

	const onHandleGetVacancy = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 6, page: params.page, param: params.search };

			response = await api.get.career.career(payload);

			setVacancyData(response.data.data.list.content);
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	const onHandleGetResourceData = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.career.resource();

			setResourceData(response.data.data.resourceConfig.contents);
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	const onHandleVacancyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedVacancy(event.target.value);

		setCurrentPage(0);
	};

	const onHandleJobTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedJobType(event.target.value);

		setCurrentPage(0);
	};

	const onHandleBack = useCallback(() => {
		if (currentPage !== 0) setCurrentPage((prev) => --prev);
	}, [currentPage]);

	const onHandleNext = useCallback(() => {
		if (currentPage !== totalPageNumber - 1) setCurrentPage((prev) => ++prev);
	}, [currentPage, totalPageNumber]);

	//prettier-ignore
	const handleTnc = useCallback((name: string, value: boolean) => {
		formik.setFieldValue(name, value);
	}, [formik]);

	const onHandleApplyNow = (jobType: string) => {
		formik.setFieldValue("roleType", jobType);

		formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	useEffect(() => {
		onHandleGetVacancy();
		onHandleGetResourceData();
	}, [onHandleGetResourceData, onHandleGetVacancy]);

	return (
		<div className="page-career">
			<div className="career">
				<AppNavbar />

				<HeaderCard backgroundImage={resolutionImg} title={"Join The Team"} />

				<div className="about-work">
					<p className="about-work__title">About Work here</p>

					<p className="about-work__description">If you are looking for a reliable technology service provider with whom you share your mind and get the best digital roadmap you've been looking for, we are here.</p>

					<div className="about-work__wrapper">
						{Array.isArray(joinTheTeamContent) && (joinTheTeamContent as JoinTheTeamValue[]).map((o, i) => <AppWorkHereCard title={o.title} description={o.description} image={laptopImg} key={i} />)}
					</div>
				</div>

				<AppSlideShow slideImages={joinTheTeamMedia} />

				<div className="vacancy">
					<p className="vacancy__title">Our open positions</p>

					<p className="vacancy__description">Join us on our mission to make financial products accessible and seamless for everyone everywhere.</p>

					<div className="vacancy__selected">
						<div className="vacancy__option">
							<AppSelectInput name="vacancy" placeholder="All Vacancies" value={selectedVacancy} onChange={onHandleVacancyChange} options={onSelectVacancyOptions} customWidth />

							<AppSelectInput name="jobTypes" placeholder="All Job Types" value={selectedJobType} onChange={onHandleJobTypeChange} options={onSelectJobTypeOptions} customWidth />
						</div>

						{totalPageNumber > 1 && <AppPagination totalPageNumber={totalPageNumber} currentPage={currentPage} onHandleNext={() => onHandleNext()} onHandleBack={() => onHandleBack()} />}
					</div>

					<div className="vacancy__wrapper">
						{currentJobData.map((o, i) => {
							return <AppPositionCard title={o.title} description={o.description} jobType={o.employmentType} key={i} onHandleApplyNow={() => onHandleApplyNow(o.title)} />;
						})}
					</div>
				</div>

				<div className="form-section" ref={formRef}>
					<p className="form-section__title">Let's get started</p>

					<p className="form-section__description">
						We're excited to connect with you and learn more about your real estate goals. Use the form below to get in touch with Estatein. Whether you're a prospective client, partner, or simply curious about our
						services, we're here to answer your questions and provide the assistance you need.
					</p>

					<form className="form-section__form" onSubmit={formik.handleSubmit}>
						<section className="form-section__form-container">
							<AppInput required type="text" name="name" label="Name" placeholder="Enter Name" value={formik.values.name} touched={formik.touched.name} error={formik.errors.name} onChange={formik.handleChange} />

							<AppInput
								required
								name="email"
								placeholder="Enter your Email"
								type="email"
								label="Email"
								value={formik.values.email}
								touched={formik.touched.email}
								error={formik.errors.email}
								onChange={formik.handleChange}
							/>

							<AppInput
								required
								type="tel"
								name="phone"
								label="Phone No."
								placeholder="Enter Phone Number"
								value={formik.values.phone}
								touched={formik.touched.phone}
								error={formik.errors.phone}
								onChange={formik.handleChange}
							/>

							<AppSelectInput
								required
								name="roleType"
								label="Role Type"
								placeholder="Select Role"
								value={formik.values.roleType}
								touched={formik.touched.roleType}
								error={formik.errors.roleType}
								options={selectOptions}
								onChange={formik.handleChange}
							/>

							<AppTextarea
								id="message"
								name="message"
								label="Message"
								placeholder="Enter your Message here..."
								value={formik.values.message}
								touched={formik.touched.message}
								error={formik.errors.message}
								onChange={formik.handleChange}
								customHeight
							/>

							<AppFileUploadField
								id="file"
								name="file"
								label="Upload CV/Resume"
								value={formik.values.file}
								touched={formik.touched.file}
								error={formik.errors.file}
								onChange={(file) => {
									formik.setFieldValue("file", file);
								}}
								maxSize={4}
							/>
						</section>

						<div className="submit-container">
							<AppCheckbox required name="terms" value={formik.values.terms} error={formik.errors.terms} touched={formik.touched.terms} onChange={handleTnc}>
								<p className="submit-container__text" id="agreeTnc">
									I agree with <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>
								</p>
							</AppCheckbox>

							<AppButton type="submit" id="submit" label="Submit Form" className="button-section__button" disabled={formik.isSubmitting} />
						</div>
					</form>
				</div>

				<AppFooter />

				<AppPopupCard ref={modalRef} image={emailReceived} title={title} />
			</div>
		</div>
	);
};

export default PageCareer;
