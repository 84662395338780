import { AppArticleInfoDto } from "dto/components/app-article-info/app-article-dto";

const AppArticleInfo = ({ publicationDate, category, tableOfContent, attachments }: AppArticleInfoDto) => {
	const onHandleDownloadPDF = () => {
		if (attachments.length > 0) {
			attachments.forEach((attachment) => {
				window.open(attachment.url, "_blank");
			});
		}
	};

	return (
		<div className="app-article-info">
			<div className="article-info">
				<div className="article-info__header">
					<div className="article-info__block">
						<p className="article-info__title">Publication Date</p>
						<p className="article-info__description">{publicationDate}</p>
					</div>

					<div className="article-info__block">
						<p className="article-info__title">Category</p>
						<p className="article-info__description">{category}</p>
					</div>
				</div>

				<div className="article-info__content">
					<p className="article-info__title">Table of Contents</p>
					<ul className="article-info__description">
						{tableOfContent?.map((desc: string, index: number) => (
							<li key={index}>{desc}</li>
						))}
					</ul>

					<button className="view-button" onClick={onHandleDownloadPDF}>
						<p className="view-button__text">Download Whitepaper</p>
					</button>
				</div>
			</div>
		</div>
	);
};
export default AppArticleInfo;
