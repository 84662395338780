// library
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// common
import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ClientDto, ArticleDto, FaqDto, PartnerListDto, ServiceDto } from "dto/pages/page-home-dto";

// component
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppNewsCard from "components/app-news-card";
import AppPagination from "components/app-pagination";
import AppArticleCard from "components/app-article-card";
import AppFaqCard from "components/pages/page-home/app-faq-card";
import AppCompanyCard from "components/pages/page-home/app-company-card";
import AppServiceSolutionCard from "components/pages/page-home/app-service-solution-card";

const PageHome = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = useRef({ search: "", page: 0, size: 9 });
	const [videoIndex, setVideoIndex] = useState<number>(0);
	const [videoKey, setVideoKey] = useState(Date.now());
	const [main1Services, setMain1Services] = useState<ServiceDto[]>([]);
	const [main2Services, setMain2Services] = useState<ServiceDto[]>([]);
	const [clientsData, setClientsData] = useState<ClientDto[]>([]);
	const [partnerList, setPartnerList] = useState<PartnerListDto[]>([]);
	const [faqData, setFaqData] = useState<FaqDto[]>([]);
	const [articleData, setArticleData] = useState<ArticleDto[]>([]);

	//prettier-ignore
	const companyIntro = useMemo(() => [
		{ desc: "We have formed a working model and have established partnership with", number: "5", workforce: "Leading Agencies", desc1: "to leverage on their talents database open for use of ATF." },
		{ desc: "We have our own talents database with", number: "3,000+", workforce: "Candidates", desc1: "and our internal recruitment team are capable to continuously identify the local experienced talents that are exploring for opportunities." },
		{ desc: "We have", number: "100+", workforce: "Permanent Employees", desc1: "and continously expanding. Join the team and help our client become the next and best versions of themselves." }
	], []);

	const onHandlePlayBackVideo = useCallback(() => {
		if (videoIndex !== 0) {
			setVideoIndex((prev) => --prev);

			setVideoKey(Date.now());
		}
	}, [videoIndex]);

	const onHandlePlayNextVideo = useCallback(() => {
		if (videoIndex !== clientsData.length - 1) {
			setVideoIndex((prev) => ++prev);

			setVideoKey(Date.now());
		}
	}, [videoIndex, clientsData.length]);

	const onHandleSwitchVideo = useCallback((index: number) => {
		setVideoIndex(index);

		setVideoKey(Date.now()); // Change key to force re-render
	}, []);

	const onHandleGetPartnerList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.partner.partner();

			setPartnerList(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetServices = useCallback(async () => {
		try {
			const params = queryParams.current;

			const payload = { size: 3, page: params.page };

			const responseMain1 = await api.get.services.services("main1", payload);

			const responseMain2 = await api.get.services.services("main2", payload);

			setMain1Services(responseMain1.data.data.list);

			setMain2Services(responseMain2.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const getYouTubeEmbedUrl = (url: string): string => {
		const videoId = url.split("v=")[1]?.split("&")[0] || url.split("youtu.be/")[1];

		return `https://www.youtube.com/embed/${videoId}?disablekb=1&controls=0&showinfo=0&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&&autoplay=1&loop=1&mute=1&playlist=${videoId}`;
	};

	const onHandleGetClientData = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.clients.clients();

			setClientsData(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetFaq = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.faq.faq();

			setFaqData(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleOurArticles = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 3, page: params.page, param: params.search };

			response = await api.get.ourArticles.ourArticles(payload);

			setArticleData(response.data.data.list.content);
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	useEffect(() => {
		onHandleGetClientData();
		onHandleGetFaq();
		onHandleOurArticles();
		onHandleGetPartnerList();
		onHandleGetServices();
	}, [onHandleGetClientData, onHandleGetFaq, onHandleGetPartnerList, onHandleGetServices, onHandleOurArticles]);

	useEffect(() => {
		const w = window.scrollTo(0, 0);
		return () => w;
	}, [location]);

	// Setup marquee effect
	useEffect(() => {
		const root = document.documentElement;
		const style = getComputedStyle(root);
		const marqueeContent = document.querySelector("ul.marquee-content");
		const marqueeElementsDisplayed = parseInt(style.getPropertyValue("--marquee-elements-displayed"), 10);

		if (marqueeContent && marqueeContent.children.length > 0) {
			root.style.setProperty("--marquee-elements", marqueeContent.children.length.toString());

			for (let i = 0; i < marqueeElementsDisplayed; i++) {
				marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
			}
		}
	}, [partnerList]);

	useEffect(() => {
		const items = ["Project Manager", "Business Analysts", "UI/UX Designers", "Software Developers", "QA & RPA Expertise", "Data Engineers", "Devops Engineers", "IT OPS Executives", "IT OPS Executives"];
		const container = document.getElementById("banner-items");

		const onHandleSlideUp = (item: HTMLLIElement, i: number) => {
			const lastItem = i === items.length - 1;

			setTimeout(() => {
				if (container && container.lastChild) container?.removeChild(container.lastChild);
				container?.appendChild(item);
				item.classList.add("slide-up-wording");

				if (lastItem) {
					onHandleAnimate();
				}
			}, 3000 * i);
		};

		const onHandleAnimate = () => {
			items.forEach((o, i) => {
				const item = document.createElement("li");
				item.className = "list__item";
				item.innerHTML = o;
				onHandleSlideUp(item, i);
			});
		};

		onHandleAnimate();
	}, []);

	return (
		<div className="page-home">
			<div className="home">
				<AppNavbar shouldHeaderFixed={true} />

				<div className="home-container">
					<div className="home__height">
						<div className="header" />
					</div>

					<div className="home-wrapper">
						<div className="context">
							<div className="content" />

							<div className="context__text">
								<span>We are</span>
								<span className="content__container">
									<ul className="list" id="banner-items" />
								</span>
								<span>to help business with digital</span>
								<br></br>
								<span>transformation</span>
							</div>
						</div>
					</div>
				</div>

				<div className="partners-section">
					<p className="partners-section__title" id="animation-3">
						Our Strategic Partners
					</p>

					<div className="marquee-wrapper">
						<div className="marquee">
							<ul className="marquee-content">
								{partnerList.map((partner) => (
									<li key={partner.id} className="item">
										<img className="img" src={partner.logo} alt={partner.name} />
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>

				<div className="services-section">
					<p className="services-section__title">How we help</p>

					<p className="services-section__description">
						If you are looking for a reliable technology service provider with whom you share your mind and get the best digital roadmap you've been looking for, we are here.
					</p>

					<p className="services-section__subtitle">Our services & solutions</p>

					<div className="services-section__wrapper">
						{main1Services.map((o) => (
							<AppServiceSolutionCard key={o.id} title={o.title} description={o.description} />
						))}
					</div>

					<p className="services-section__subtitle">Professional service with partner solutions</p>

					<div className="services-section__wrapper">
						{main2Services.map((o) => (
							<AppServiceSolutionCard key={o.id} title={o.title} description={o.description} />
						))}
					</div>

					<div className="button-container">
						{/*prettier-ignore*/}
						<button className="view-button" onClick={() => {navigate(pathnames.PageAllProducts)}}>
							<p className="view-button__text">Learn More</p>
						</button>
					</div>
				</div>

				<div className="company-intro">
					<p className="company-intro__title">Why Altecflex</p>

					<p className="company-intro__description">If you are looking for a reliable technology service provider with whom you.</p>

					<div className="company-intro__wrapper">
						{companyIntro.map((o, i) => {
							return <AppCompanyCard description={o.desc} number={o.number} workforce={o.workforce} description1={o.desc1} key={i} />;
						})}
					</div>
				</div>

				<div className="clients-section">
					<div className="clients-section__body">
						<div className="clients-section__wrapper">
							<p className="clients-section__title">Our Clients</p>

							{clientsData.length > 0 && (
								<div key={clientsData[videoIndex].id}>
									<p className="clients-section__sub-title">{clientsData[videoIndex].title}</p>

									<p className="clients-section__description">{clientsData[videoIndex].information}</p>

									{/* prettier-ignore */}
									<button className="view-button" onClick={() => {navigate(`${pathnames.PageProjectShowCase}/${clientsData[videoIndex].id}`)}}>
										<p className="view-button__text">View Project</p>
									</button>
								</div>
							)}

							<AppPagination totalPageNumber={clientsData.length} currentPage={videoIndex} onHandleNext={onHandlePlayNextVideo} onHandleBack={onHandlePlayBackVideo} />
						</div>

						<div className="clients-section__container">
							<div className="clients-section__slider-container">
								<div className="slider">
									{clientsData.map((o, i) => {
										const imageClassName = classNames({ slider__image: true, "slider__image--active": i === videoIndex });
										return (
											<div className="slider__item" key={i} onClick={() => onHandleSwitchVideo(i)}>
												<div className={imageClassName} style={{ backgroundImage: `url(${o.thumbnail})` }} />

												<div className="slider__wrapper">
													<p className="slider__title">{o.title}</p>
													<p className="slider__description">{o.information}</p>
													<p className="slider__text">{o.platform}</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						{clientsData.length > 0 && (
							<div className="clients-section__youtube">
								<iframe
									key={videoKey}
									width="560"
									height="315"
									src={getYouTubeEmbedUrl(clientsData[videoIndex].cover)}
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									title={`YouTube video ${videoIndex}`}
								/>
							</div>
						)}
					</div>
				</div>

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">Our Articles</p>

						{/* prettier-ignore */}
						<button className="view-button" onClick={() => {navigate(pathnames.PageAllArticles)}}>
							<p className="view-button__text">View All Articles</p>
						</button>
					</div>

					<div className="articles-section__wrapper">
						{articleData.map((o, i) => {
							const articleLink = `${pathnames.PageArticle}/${o.id}`;

							return <AppArticleCard title={o.title} description={o.category} image={o.thumbnail} key={i} link={articleLink} />;
						})}
					</div>
				</div>

				<div className="faq-section">
					<p className="faq-section__title">FAQ</p>

					<p className="faq-section__description">{`Got questions? We've got answers! Check out our FAQ section to find answers to the most common questions about ATF.`}</p>

					<div className="faq-section__container">
						{faqData.map((o, i) => {
							return <AppFaqCard question={o.question} answer={o.answer} index={i} key={i} />;
						})}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageHome;
