import calendar from "assets/images/calendar.svg";
import grid from "assets/images/grid.svg";

interface ProjectShowCaseInfoProps {
	description?: string[];
	client?: string;
	type?: string;
	whatWeDidItems?: string[];
	genres?: string[];
}

const ProjectShowCaseInfo: React.FC<ProjectShowCaseInfoProps> = ({ description = [], client = "", type = "", whatWeDidItems = [], genres = [] }) => {
	return (
		<div className="app-project-info">
			<div className="app-project-info__left">
				<div className="project-info">
					<div className="project-info__header">
						<div className="project-info__block">
							<p className="project-info__title">
								<img src={calendar} alt="" /> Client
							</p>

							<p className="project-info__description">{client}</p>
						</div>

						<div className="project-info__block">
							<p className="project-info__title">
								<img src={calendar} alt="" /> Type
							</p>

							<p className="project-info__description">{type}</p>
						</div>
					</div>

					<div className="project-info__content">
						<p className="project-info__title">
							<img src={calendar} alt="" /> What we did
						</p>

						<div className="project-info__description">
							{whatWeDidItems.map((item: string, index: number) => (
								<p key={index} className="project-info__description-text">
									{item}
								</p>
							))}
						</div>
					</div>

					<div className="project-info__content">
						<p className="project-info__title">
							<img src={grid} alt="" /> Genres
						</p>

						<div className="project-info__genres">
							{genres.map((item: string, index: number) => (
								<p className="project-info__genres-text" key={index}>
									{item}
								</p>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className="app-project-info__right">
				<div className="project-desc">
					<p className="project-desc__title">Description</p>
					{Array.isArray(description) ? (
						description.map((desc: string, index: any) => (
							<p className="project-desc__content" key={index}>
								{desc}
							</p>
						))
					) : (
						<p className="project-desc__content">{description}</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProjectShowCaseInfo;
