import { useCallback } from "react";

import leftArrowIcon from "assets/images/components/app-pagination/arrow-left-icon.svg";
import rightArrowIcon from "assets/images/components/app-pagination/arrow-right-icon.svg";

const AppPagination = ({ totalPageNumber, currentPage, onHandleBack, onHandleNext }: any) => {
	const onHandleRenderStepper = useCallback(() => {
		const content = [];
		for (let i = 0; i < totalPageNumber; ++i) {
			if (i === currentPage) {
				content.push(<div className="stepper--active" key={i} />);
			} else {
				content.push(<div className="stepper" key={i} />);
			}
		}
		return content;
	}, [currentPage, totalPageNumber]);

	return (
		<div className="app-pagination">
			<div className="pagination">
				<div className="pagination__container">
					<img className="pagination__icon" src={leftArrowIcon} onClick={onHandleBack} alt={"leftArrowIcon"} />
				</div>

				<div className="pagination__wrapper">{onHandleRenderStepper()}</div>

				<div className="pagination__container">
					<img className="pagination__icon" src={rightArrowIcon} onClick={onHandleNext} alt={"rightArrowIcon"} />
				</div>
			</div>
		</div>
	);
};
export default AppPagination;
