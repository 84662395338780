import emailIcon from "assets/images/components/app-office-card/email-icon.svg";
import AppOfficeCardDto from "dto/components/app-office-card/app-office-card-dto";

const AppOfficeCard = ({ title, location, address, email, googleMapLink }: AppOfficeCardDto) => {
	return (
		<div className="app-office-card">
			<div className="office-card">
				<p className="office-card__title">{title}</p>
				<p className="office-card__location">{location}</p>
				<p className="office-card__address">{address}</p>

				<div className="office-card__email">
					<img src={emailIcon} alt="email" className="office-card__icon" />

					<a href={`mailto:${email}`}>{email}</a>
				</div>

				<a href={googleMapLink} target="_blank" rel="noopener noreferrer" className="office-card__button">
					Get Directions
				</a>
			</div>
		</div>
	);
};

export default AppOfficeCard;
